import React, { useEffect, useState, Fragment } from "react";
import {
  Typography,
  Grid,
  Container,
  Button,
  Box,
  Toolbar,
  Drawer,
  ImageList,
  ImageListItem,
  IconButton,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import { Row, Col } from "react-bootstrap";
import KioskPad from "./components/Keypad";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput from "react-phone-number-input";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { NavBar } from "./navigation/NavBar";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/numbersWithComma";
import moment from "moment";
import { Form, Input } from "../../components";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { removeExpiredItems, setTip } from "../../redux/cartReducer/actions";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  DayPicker,
  SingleDatePicker,
  isInclusivelyAfterDay,
} from "react-dates";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import AddIcon from "@material-ui/icons/Add";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  setSelectedServiceOption,
  setOrderProcessingDate,
  setSelectedAddress,
} from "../../redux/publicReducer/actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import Select from "react-select";
import {
  setModeOfPayment,
  setPromoCode,
  setTotalDiscount,
} from "../../redux/cartReducer/actions";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useCookies } from "react-cookie";

import { rewardsService } from "../../services";
import { RewardsAsPayment } from "./rewards";
import { GiftCardAsPayment } from "./giftcards";
import axios from "axios";

import PaymentNested from "./components/PaymentNested";
import { MenuNavigation } from "./components/MenuNavigation";
import { DeliveryMap } from "./DeliveryMapV2";

import BlankSpace from "./components/BlankSpace";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Swal from "sweetalert2";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { WidthFull } from "@mui/icons-material";
import { Cart } from "../../features/eComm/cart/Cart";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import TextField from "@mui/material/TextField";
import AddShoppingCart from "@mui/icons-material/AddShoppingCart";
import { enableRevenueCenter } from "utils/helpers";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;

let enablePickup = process.env.REACT_APP_PICKUP;
let enableDineIN = process.env.REACT_APP_DINE_IN;
let enalbeDelivery = process.env.REACT_APP_DELIVERY;
const enableSameDayPickup = process.env.REACT_APP_ENABLE_SAME_DAY_PICKUP;
const enalbeSameDayDelivery = process.env.REACT_APP_ENABLE_SAME_DAY_DELIVERY;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const enableTip = process.env.REACT_APP_ENABLE_TIP;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textHighlight = process.env.REACT_APP_BACKGROUND_COLOR;

const getCookie = (key) => {
  var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  return keyValue ? keyValue[2] : null;
};

const setCookie = (key, value) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
  document.cookie =
    key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "0 1rem",
  marginLeft: "auto",
  marginRight: "auto",
};

const buttonStyle = {
  flex: 1,
  height: "5.5rem",
  width: "100%",
  color: buttonText,
};

const buttonWithGapStyle = {
  ...buttonStyle,
  marginRight: ".5rem",
};

export const Checkout = (props) => {
  const [showTaxesAndFees, setShowTaxesAndFees] = useState(false);
  const [openTips, setOpenTips] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disableCheckoutMessage, setDisableCheckoutMessage] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [transactionFeeName, setTransactionFeeName] = useState("");
  const [tax, setTax] = useState();
  const history = useHistory();
  const [openServiceOptions, setOpenServiceOptions] = useState(false);
  const [openSelectAddress, setOpenSelectAddress] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [deliveryChargePromoMessage, setDeliveryChargePromoMessage] = useState(
    process.env.REACT_APP_DELIVERY_CHARGE_MESSAGE
  );
  const [deliveryFreeAmount, setDeliveryFreeAmount] = useState(
    process.env.REACT_APP_DELIVERY_FREE_AMOUNT
  );
  const [deliveryMinimumAmount, setDeliveryMinimumAmount] = useState(
    process.env.REACT_APP_DELIVERY_MINIMUM_AMOUNT
  );
  const [selectedAddressDeliveryCharge, setSelectedAddressDeliveryCharge] =
    useState(0);
  const [openUserCards, setOpenUserCards] = useState(false);
  const [tempPromoCode, setTempPromoCode] = useState("");
  const [cookiePayment, setCookiePayment, removeCookiePayment] = useCookies([
    "payment",
  ]);

  const [timeConditions, setTimeConditions] = useState([
    {
      day: 0,
      startHour: 0,
      endHour: 0,
      endMinutes: 0,
      interval: 0,
      isClose: 1,
    },
    {
      day: 1,
      startHour: 0,
      endHour: 0,
      endMinutes: 0,
      interval: 0,
      isClose: 1,
    },
    {
      day: 2,
      startHour: 0,
      endHour: 0,
      endMinutes: 0,
      interval: 0,
      isClose: 1,
    },
    {
      day: 3,
      startHour: 0,
      endHour: 0,
      endMinutes: 0,
      interval: 0,
      isClose: 1,
    },
    {
      day: 4,
      startHour: 0,
      endHour: 0,
      endMinutes: 0,
      interval: 0,
      isClose: 1,
    },
    {
      day: 5,
      startHour: 0,
      endHour: 0,
      endMinutes: 0,
      interval: 0,
      isClose: 1,
    },
    {
      day: 6,
      startHour: 0,
      endHour: 0,
      endMinutes: 0,
      interval: 0,
      isClose: 1,
    },
  ]);

  const [startHour, setStartHour] = useState();
  const [endHour, setEndHour] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [endMinutes, setEndMinutes] = useState();
  const [interval, setTimeInterval] = useState();
  const [openDays, setOpenDays] = useState([]);

  const [cookieOrderId, setCookieOrderId, removeCookieOrderId] = useCookies([
    "",
  ]);
  //onsreen keyboard
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardVisibleName, setKeyboardVisibleName] = useState(false);
  const [messageToKitchen, setMessageToKitchen] = useState(
    sessionStorage.getItem("instuctions")
  );
  const [name, setName] = useState(sessionStorage.getItem("name"));

  const Swal = require("sweetalert2");

  const [loading1, setLoading] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const {
    cartItems,
    totalPriceItems,
    totalDiscount,
    tips,
    selectedPayment,
    promoCode,
    orderSession,
  } = useSelector((state) => state.cart);

  const {
    orderProcessingDate,
    orderProcessingType,
    selectedAddress,
    user,
    loading,
  } = useSelector((state) => state.public);

  const dispatch = useDispatch();

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  // console.log(watchMessage, "MESSAGE");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  const [updateTime, setUpdateTime] = useState("");

  //Set default serviceOption
  useEffect(() => {
    if (sessionStorage.getItem("table") != "none") {
      enablePickup = 0;
      enalbeDelivery = 0;
      enableDineIN = 1;
      dispatch(setSelectedServiceOption(1));
    }
  }, []);

  //--Business Hours--//
  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/site/get-business-hours/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setTimeConditions(response.data);
        }
      })
      .catch((error) => {});
  }, [updateTime]);

  //--Time Conditions--//
  useEffect(() => {
    const currentDate = new Date();
    const selectedDate =
      Date.parse(orderProcessingDate) > 0
        ? new Date(orderProcessingDate)
        : currentDate;

    const tc = timeConditions.find((t) => t.day == selectedDate.getDay());
    setStartHour(tc && tc.startHour);
    setEndHour(tc && tc.endHour);
    setOpenDays(timeConditions.map((i) => i.day));

    // if (tc.startHour < 11) {
    //   setStartTime((tc.startHour + (11 - tc.startHour)) * 60);
    //   setEndTime(tc.endHour * 60 + 20 + tc.endMinutes);
    // } else {
    //   setStartTime(tc.startHour * 60);
    //   setEndTime(tc.endHour * 60 + 20 + tc.endMinutes);
    // }

    setEndMinutes(tc && tc.endMinutes);
    //setTimeInterval(tc && tc.interval);//this will be used for interval on SIC
  }, [orderProcessingDate, timeConditions]);
  //temporarily disabled
  // useEffect(() => {
  //   var sourceUpdateTime = new EventSource(
  //     "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" +
  //       encodeURIComponent(siteId)
  //   );

  //   sourceUpdateTime.onmessage = function (event) {
  //     var u_items = JSON.parse(event.data);
  //     if (
  //       u_items[0]?.time &&
  //       sessionStorage.getItem("update_date_time") !== u_items[0].time
  //     ) {
  //       setUpdateTime(u_items[0].time);
  //       sessionStorage.setItem("update_date_time", u_items[0].time);
  //     }
  //   };

  //   sourceUpdateTime.onerror = function (error) {
  //     console.error("EventSource failed:", error);
  //   };

  //   window.addEventListener("beforeunload", function () {
  //     sourceUpdateTime.close(); // Close EventSource when the page is closed or reloaded
  //   });
  // }, []);

  useEffect(() => {
    dispatch(removeExpiredItems());
    const intervalId = setInterval(() => {
      dispatch(removeExpiredItems());
    }, 60000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/customer/checkout-enabled/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        if (response.data.OnOff < 1) {
          Swal.fire({
            title: "Store Message",
            text: response.data.eCommerceDisplayMessage,
            icon: "info",
            confirmButtonText: "OK",
          });
          history.push("/");
        }
      })
      .catch((error) => {});
  }, [updateTime]);

  //set service charge
  useEffect(() => {
    if (process.env.REACT_APP_SERVICE_FEE > 0) {
      setServiceCharge(process.env.REACT_APP_SERVICE_FEE);
    }
    if (process.env.REACT_APP_SERVICE_CHARGE_PERCENT > 0) {
      setServiceCharge(
        numberWithCommas(
          parseFloat(
            (totalPriceItems - discount) *
              (process.env.REACT_APP_SERVICE_CHARGE_PERCENT / 100)
          )
        )
      );
    }
    console.log("service:" + serviceCharge);
  }, [updateTime, totalPriceItems, discount]);

  // get Transfer Fee
  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/site/get-service-fee/" +
      serverId +
      "/" +
      siteId;
    axios
      .get(url)
      .then((response) => {
        if (response.data.SIC_EnableInWEB == 1) {
          setTransactionFee(response.data.ServiceFeeAmount);
          setTransactionFeeName(response.data.ServiceFeeName);
        }
      })
      .catch((error) => {});
  }, [updateTime]);

  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  const handleOnKeyPress = (button) => {
    if (button === "{enter}") {
      setKeyboardVisible(false);
    }
  };

  const handleOnChange = (value) => {
    setMessageToKitchen(value);
    sessionStorage.setItem("instuctions", value);
  };

  const handleOnKeyPressName = (button) => {
    if (button === "{enter}") {
      setKeyboardVisibleName(false);
    }
  };

  const handleOnChangeName = (value) => {
    setName(value);
    sessionStorage.setItem("name", value);
  };

  useEffect(() => {
    //set date as current date
    dispatch(setOrderProcessingDate(moment()));

    const d = new Date();
    const hour = d.getHours(); //get current hour
    const min = d.getMinutes(); //get current min
    const cdate = d.getDate();
    const cyear = d.getFullYear();
    const cmonth = d.getMonth();

    if (
      d.getDate() == cdate &&
      d.getMonth() == cmonth &&
      d.getFullYear() == cyear &&
      (hour > endHour || (hour == endHour && min >= endMinutes))
    ) {
      //alert("Store is closed. You can order for next day");
      dispatch(setOrderProcessingDate(moment(d, "MM-DD-YYYY").add(1, "days")));
    }

    const url =
      "https://ecommv2.servingintel.com/items/tax/" +
      siteId +
      "?server_id=" +
      serverId;
    axios
      .get(url)
      .then((response) => {
        setTax(
          parseFloat(parseFloat(response.data[0].TaxPercent / 100) / 100) / 100
        );
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // console.log("pc:" + promoCode);
    if (cookiePayment?.payment?.id) {
      dispatch(setModeOfPayment(cookiePayment.payment));
    }
    if (cookiePayment?.tips) {
      dispatch(setTip(cookiePayment.tips));
    }
    if (cookiePayment.message) {
      setValue("message", cookiePayment.message);
    }
    if (cookiePayment.promoCode) {
      dispatch(setPromoCode(cookiePayment.promoCode));
      setTempPromoCode(cookiePayment.promoCode);
    }
  }, []);

  //console.log(cookiePayment, "COOKIE");

  useEffect(() => {
    // if (user?.address) {
    //   dispatch(setSelectedAddress(user.address));
    // }
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    } else {
      const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        address: selectedAddress ? selectedAddress.address : "",
        address2: "",
        city: selectedAddress ? selectedAddress.city : "",
        zipCode: selectedAddress ? selectedAddress.zipCode : "",
        phone: "",
      };

      dispatch(setSelectedAddress(initialValues));
    }
  }, []);

  useEffect(() => {
    if (selectedPayment) {
      setCookiePayment("payment", selectedPayment, { path: "/" });
    } else {
      removeCookiePayment("payment", { path: "/" });
    }
    if (tips) {
      setCookiePayment("tips", tips, { path: "/" });
    } else {
      removeCookiePayment("tips", { path: "/" });
    }

    if (promoCode) {
      setCookiePayment("promoCode", promoCode, { path: "/" });
    } else {
      removeCookiePayment("promoCode", { path: "/" });
    }

    if (watchMessage) {
      setCookiePayment("message", watchMessage, { path: "/" });
    } else {
      removeCookiePayment("message", { path: "/" });
    }
  }, [selectedPayment, tips, watchMessage, promoCode]);

  useEffect(() => {
    if (tempPromoCode || promoCode) {
      setDiscount(0);
      setDiscountPercentage(0);
      const tpc =
        tempPromoCode == ""
          ? promoCode != ""
            ? promoCode
            : "coupon"
          : promoCode;
      const url =
        "https://ecommv2.servingintel.com/payment/coupon/" +
        serverId +
        "/" +
        siteId +
        "/" +
        tpc;
      //const url = "/servingintel_api/public/items/all";
      axios
        .get(url)
        .then((response) => {
          if (response.data.length == 0) {
            dispatch(setPromoCode(""));
            //alert("Invalid Coupon");
          } else {
            const dept = response.data.department;
            const itemId = response.data.itemId;
            const percent = response.data.discount_percent;
            const discount = response.data.discount;
            const subTotal = response.data.subTotal;

            setDiscountPercentage(parseFloat(percent * 100));

            if (dept !== "" || itemId.length > 0) {
              //by deparment
              let totalDiscountPrice = 0;
              cartItems.forEach((item) => {
                //department level
                if (item.Department == dept) {
                  totalDiscountPrice =
                    parseFloat(totalDiscountPrice) +
                    parseFloat((item.DefaultPrice / 100) * item.quantity);
                }

                //item level
                if (itemId.includes(item.ItemID.toLowerCase())) {
                  totalDiscountPrice =
                    totalDiscountPrice === 0
                      ? parseFloat(item.DefaultPrice / 100)
                      : totalDiscountPrice > parseFloat(item.DefaultPrice / 100)
                      ? parseFloat(item.DefaultPrice / 100)
                      : totalDiscountPrice;
                }
              });

              if (totalDiscountPrice > 0) {
                //applicable items
                if (discount == 0 && percent == 0) {
                  //item level discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice));
                  } else {
                    // dispatch(setPromoCode(""));
                    // alert(
                    //   "Total item(s) price did not meet the minimum required for this coupon which is $" +
                    //     subTotal +
                    //     "."
                    // );
                  }
                }

                if (discount > 0) {
                  // direct amount discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(discount));
                  } else {
                    // dispatch(setPromoCode(""));
                    // alert(
                    //   "Total item(s) price did not meet the minimum required for this coupon which is $" +
                    //     subTotal +
                    //     "."
                    // );
                  }
                }

                if (percent > 0) {
                  //percentage discount
                  if (totalPriceItems >= subTotal) {
                    setDiscount(parseFloat(totalDiscountPrice * percent));
                  } else {
                    // dispatch(setPromoCode(""));
                    // alert(
                    //   "Total item(s) price did not meet the minimum required for this coupon which is $" +
                    //     subTotal +
                    //     "."
                    // );
                  }
                }
              } else {
                dispatch(setPromoCode(""));
                //alert("Coupon is not applicable to the existing Check Item(s).")
              }
            } else {
              //all items
              setDiscount(parseFloat(totalPriceItems * percent));
            }
          }
        })
        .catch((error) => {});
    }
  }, [totalPriceItems]);

  useEffect(() => {
    dispatch(setTotalDiscount(discount));
  }, [discount]);

  useEffect(() => {
    if (orderProcessingType !== 2 || deliveryCharge == 0) {
      setDeliveryCharge(0);
    }
  }, [orderProcessingType, deliveryCharge]);

  //---Start Delivery Charge Logic---//
  const updateDeliveryCharge = (charge) => {
    setSelectedAddressDeliveryCharge(charge);
    setDeliveryCharge(
      totalPriceItems >= deliveryFreeAmount && deliveryFreeAmount > 0
        ? 0
        : charge
    );
  };

  useEffect(() => {
    if (orderProcessingType == 2) {
      setDeliveryCharge(
        totalPriceItems >= deliveryFreeAmount && deliveryFreeAmount > 0
          ? 0
          : selectedAddressDeliveryCharge
      );
    }
  }, [orderProcessingType, totalPriceItems]);

  useEffect(() => {
    if (
      orderProcessingType == 2 &&
      deliveryMinimumAmount > 0 &&
      totalPriceItems < deliveryMinimumAmount
    ) {
      Swal.fire({
        title: "Store Message",
        text: "Minimum amount for delivery is $" + deliveryMinimumAmount,
        icon: "info",
        confirmButtonText: "OK",
      });
      dispatch(setSelectedServiceOption(0));
    }
  }, [orderProcessingType, totalPriceItems]);
  //---End Delivery Charge Logic---//

  //---Start Set Interval---//
  useEffect(() => {
    if (orderProcessingType === 0) {
      setTimeInterval(parseInt(process.env.REACT_APP_PICKUP_INTERVAL));
    }
    if (orderProcessingType === 2) {
      setTimeInterval(parseInt(process.env.REACT_APP_DELIVERY_INTERVAL));
    }
  }, [orderProcessingType]);
  //---End Set Interval---//

  const applyCoupon = () => {
    dispatch(setPromoCode(tempPromoCode));
    setDiscount(0);
    setDiscountPercentage(0);

    const url =
      "https://ecommv2.servingintel.com/payment/coupon/" +
      serverId +
      "/" +
      siteId +
      "/" +
      tempPromoCode;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (response.data.length == 0) {
          dispatch(setPromoCode(""));
          alert("Invalid Coupon");
        } else {
          const dept = response.data.department;
          const itemId = response.data.itemId;
          const percent = response.data.discount_percent;
          const discount = response.data.discount;
          const subTotal = response.data.subTotal;

          setDiscountPercentage(parseFloat(percent * 100));

          if (dept !== "" || itemId.length > 0) {
            //by deparment
            let totalDiscountPrice = 0;
            cartItems.forEach((item) => {
              //department level
              if (item.Department == dept) {
                totalDiscountPrice =
                  parseFloat(totalDiscountPrice) +
                  parseFloat((item.DefaultPrice / 100) * item.quantity);
              }

              //item level
              if (itemId.includes(item.ItemID.toLowerCase())) {
                totalDiscountPrice =
                  totalDiscountPrice === 0
                    ? parseFloat(item.DefaultPrice / 100)
                    : totalDiscountPrice > parseFloat(item.DefaultPrice / 100)
                    ? parseFloat(item.DefaultPrice / 100)
                    : totalDiscountPrice;
              }
            });

            if (totalDiscountPrice > 0) {
              //applicable items

              if (discount === 0 && percent === 0) {
                //item level discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice));
                } else {
                  dispatch(setPromoCode(""));

                  Swal.fire({
                    title: "Store Message",
                    text:
                      "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      ".",
                    icon: "info",
                    confirmButtonText: "OK",
                  });
                }
              }

              if (discount > 0) {
                // direct amount discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(discount));
                } else {
                  dispatch(setPromoCode(""));
                  Swal.fire({
                    title: "Store Message",
                    text:
                      "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      ".",
                    icon: "info",
                    confirmButtonText: "OK",
                  });
                }
              }

              if (percent > 0) {
                //percentage discount
                if (totalPriceItems >= subTotal) {
                  setDiscount(parseFloat(totalDiscountPrice * percent));
                } else {
                  dispatch(setPromoCode(""));
                  Swal.fire({
                    title: "Store Message",
                    text:
                      "Total item(s) price did not meet the minimum required for this coupon which is $" +
                      subTotal +
                      ".",
                    icon: "info",
                    confirmButtonText: "OK",
                  });
                }
              }
            } else {
              dispatch(setPromoCode(""));

              Swal.fire({
                title: "Store Message",
                text: "Coupon is not applicable to the existing Check Item(s).",
                icon: "info",
                confirmButtonText: "OK",
              });
            }
          } else {
            //all items
            setDiscount(parseFloat(totalPriceItems * percent));
          }
        }
        console.log(discount, "discount");
      })
      .catch((error) => {});
  };

  const processCardSwipePayment = () => {
    if (cartItems.length === 0) {
      Swal.fire({
        icon: "error",
        title: "NO ITEMS IN CHECK!",
        text: "PLEASE SELECT AN ITEM FROM THE MENU PAGE",
        confirmButtonText: "OK",
        confirmButtonColor: buttonColor,
      });
    } else {
      if (orderProcessingDate) {
        if (!sessionStorage.getItem("name")) {
          Swal.fire({
            icon: "info",
            title: "Store Message",
            text: "Please enter your name.",
            confirmButtonText: "OK",
            confirmButtonColor: buttonColor,
          });
        } else {
          setOrder();
          Swal.fire({
            icon: "info",
            title: "Please follow instructions on payment device.",
            text: "",
            imageUrl: "/static/right-arrow.gif",
            imageHeight: 250,
            imageAlt: "FOLLOW INSTRUCTIONS ON PAYMENT DEVICE",
            confirmButtonText: "OK",
            confirmButtonColor: buttonColor,
            showConfirmButton: false,
            allowOutsideClick: false,
          });
        }
      }
    }
  };

  const newOrder = () => {
    Swal.fire({
      title: "New Order",
      text: "This will clear item(s) on cart. Are you sure you want to continue?",
      icon: "info",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "CANCEL",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        sessionStorage.clear();
        history.goBack();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  };

  const setOrder = () => {
    // if (!sessionStorage.getItem("order_id")) {
    const subTotal = promoCode
      ? parseFloat(totalPriceItems) - parseFloat(totalDiscount)
      : totalPriceItems;

    let total =
      parseFloat(tips) + parseFloat(subTotal) + parseFloat(subTotal * tax);

    total =
      serviceCharge > 0 ? parseFloat(total) + parseFloat(serviceCharge) : total;

    total =
      transactionFee > 0
        ? parseFloat(total) + parseFloat(transactionFee)
        : total;

    total =
      orderProcessingType == 2 && deliveryCharge > 0
        ? parseFloat(total) + parseFloat(deliveryCharge)
        : total;
    setCookie("sic_email", selectedAddress.email);
    setCookie("sic_phone", selectedAddress.phone);
    setCookie("sic_name", selectedAddress.first_name);
    setCookie("sic_address", JSON.stringify(selectedAddress));
    //const url ="http://localhost/servingintel_api/public/postpayment/setOrder";
    const url =
      "https://ecommv2.servingintel.com/postpayment/setOrder/" +
      siteId +
      "?server_id=" +
      serverId;
    const d = new Date(orderProcessingDate);
    axios
      .post(url, {
        cartItems: cartItems,
        totalPriceItems: totalPriceItems,
        total: total,
        subTotal: subTotal,
        tips: tips,
        tax: tax,
        deliveryCharge: orderProcessingType == 2 ? deliveryCharge : "0",
        serviceCharge: serviceCharge > 0 ? serviceCharge : "0",
        transactionFee: transactionFee > 0 ? transactionFee : "0",
        promoCode: promoCode,
        pid: "eKioks",
        order_id: parseInt(sessionStorage.getItem("order_id")),
        orderProcessingType:
          orderProcessingType == 0
            ? "pickup"
            : orderProcessingType == 1
            ? "dine-in"
            : "delivery",
        orderProcessingDate: {
          date: d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(),
          time: d.getHours() + ":" + d.getMinutes(),
        },
        selectedAddress: selectedAddress,
        instructions: sessionStorage.getItem("instuctions"),
        name: sessionStorage.getItem("name"),
        serverName: "eKiosk",
        tableNumber: sessionStorage.getItem("table"),
      })
      .then((response) => {
        sessionStorage.setItem("order_id", response.data.order_id);
        setCookieOrderId("orderId", response.data.order_id, {
          path: "/",
        });
        sessionStorage.setItem("user_id", response.data.user_id);
        sendOrder();
      })
      .catch((error) => {
        console.log(error);
      });
    // } else {
    //   sendOrder();
    // }
  };

  const sendOrder = () => {
    setLoading(true);
    const url =
      "https://ecommv2.servingintel.com/postpayment/send_econnect/" +
      siteId +
      "/" +
      storeId +
      "/" +
      sessionStorage.getItem("order_id") +
      "?server_id=" +
      serverId;
    axios
      .get(url)
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.data) {
          verifyPayment();
        }
      })
      .catch((error) => {});
  };

  let retryCount = 0;
  const maxRetries = 30;
  const retryDelay = 5000; // 5 seconds

  const verifyPayment = () => {
    if (retryCount >= maxRetries) {
      // Handle max retry reached, perhaps show an error message to the user
      Swal.fire({
        title: "Error",
        text: "Maximum retries reached. Please check your connection or try again later.",
        icon: "error",
      });
      return;
    }

    setLoading(true);

    const url =
      "https://ecommv2.servingintel.com/postpayment/ekiosk-verify-payment-status/" +
      serverId +
      "/" +
      siteId +
      "/" +
      sessionStorage.getItem("order_id");

    axios
      .get(url)
      .then((response) => {
        const data = response.data;
        if (data.isPaid === 1 && data.status === "Approved") {
          localStorage.clear();
          sessionStorage.clear();
          successOrder();
        } else if (data.isPaid === 0) {
          retryCount++;
          setTimeout(verifyPayment, retryDelay); // retry after a delay
        } else {
          retryCount = 0;
          Swal.fire({
            title: "Store Message",
            text: "Payment Failed! " + data.status + ". Please try again.",
            icon: "warning",
            confirmButtonText: "Retry",
            showCancelButton: true,
            cancelButtonText: "Cancel Order",
          }).then((result) => {
            if (result.isConfirmed) {
              processCardSwipePayment();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              localStorage.clear();
              sessionStorage.clear();
              setTimeout(() => {
                history.push("/");
              }, 1000);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const successOrder = () => {
    Swal.fire({
      title: "Success!",
      text: "Order has been sent to the kitchen!",
      icon: "success",
      showConfirmButton: true,
      confirmButtonText: "OK",
    }).then((result) => {
      // Clear the timeout when the user clicks on the OK button
      if (result.isConfirmed) {
        clearTimeout(autoCloseTimer);
      }
    });

    // Set a timeout to auto-dismiss the alert after 5 seconds
    let autoCloseTimer = setTimeout(() => {
      Swal.close();
    }, 5000);
    history.push("/");
  };

  return (
    <React.Fragment>
      {/* Checkout Disable Modal */}
      {/* <Modal
        show={showModal}
        style={{ "z-index": "99999" }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Checkout Reminder!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            <p>{disableCheckoutMessage}</p>
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Grid container justify="center" className="content">
            <Button
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              style={{
                textTransform: "none",
                padding: "0rem 1rem",
                borderRadius: "10px",
                border: "1px solid #bebebe",
                fontSize: "large",
                width: "50%",
              }}
              onClick={() => {
                setShowModal(false);
                history.push("/");
              }}
            >
              OK
            </Button>
          </Grid>
        </Modal.Footer>
      </Modal> */}
      {enableKiosk === "0" ? <MenuNavigation /> : null}
      <div
        className={enableKiosk === "0" ? "HeaderImg" : "HeaderImgeKiosk"}
        style={{
          backgroundImage:
            enableKiosk === "0"
              ? `url(${siteMedSrc}ContactSliderImg.png)`
              : null,
          color: enableKiosk === "0" ? buttonText : null,
          height: enableKiosk === "0" ? "220px" : null,
        }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <h2
                style={{
                  color: enableKiosk === "0" ? textHighlight : null,
                }}
                className={
                  enableKiosk === "0"
                    ? "blankspace center"
                    : "padding-bottom-20 center black"
                }
              >
                Your Payment and Billing Details
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Checkout: Accept Payments Online</title>
          <link rel="canonical" href="/checkout" />
          <meta
            name="description"
            content="We offer mini takeaway pies as well as occasionally, full-size ones."
          />
        </Helmet>
      </HelmetProvider>
      {enableKiosk === "0" ? <NavBar /> : <Cart isHidden={1} />}
      <Container>
        <Typography
          component="h2"
          variant="subtitle1"
          style={{
            fontWeight: 700,
            fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
          }}
          gutterBottom
        >
          MY ORDER{" "}
          <Button
            style={{
              textTransform: "none",
              fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
              backgroundColor: buttonColor,
              color: buttonText,
              borderColor: buttonColor,
            }}
            onClick={() => history.goBack()}
          >
            Add More
          </Button>
          {cartItems.length > 0 && (
            <Button
              style={{
                marginLeft: "1rem",
                textTransform: "none",
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                backgroundColor: buttonColor,
                color: buttonText,
                borderColor: buttonColor,
              }}
              onClick={() => {
                dispatch({
                  type: "SET_CART",
                  payload: [],
                });
                sessionStorage.clear();
                if (parseInt(enableRevenueCenter) === 1) {
                  history.push("/");
                } else {
                  history.goBack();
                }
              }}
            >
              Clear Cart
            </Button>
          )}
        </Typography>
        <Box height="1rem" />

        {parseInt(enableKiosk) === 0 && (
          <>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
              }}
            >
              Promo Code
            </Typography>
            <Box display="flex" position="relative">
              {enableKiosk === "0" ? (
                <input
                  className="couponcode"
                  placeholder="Enter coupon code"
                  value={tempPromoCode}
                  onChange={(e) => setTempPromoCode(e.target.value)}
                  style={{ fontSize: enableKiosk === "0" ? "1rem" : "1.25rem" }}
                />
              ) : (
                <KioskPad />
              )}
              <Button
                id="coupon_btn"
                style={{
                  fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                  position: "absolute",
                  right: "5px",
                  top: "1px",
                  backgroundColor: buttonColor,
                  color: buttonText,
                  borderColor: buttonColor,
                }}
                onClick={() => {
                  applyCoupon();
                }}
              >
                Apply
              </Button>
            </Box>
          </>
        )}
        <br />
        <Grid container spacing={1}>
          {cartItems.length === 0 && (
            <Typography
              style={{
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                textAlign: "center",
                width: "100%",
                color: "#bebebe",
              }}
              variant="h6"
            >
              NO ITEMS IN CHECK!
            </Typography>
          )}
          {cartItems.map((item) => {
            const modifiers = item.modifiers.sort(
              (a, b) => parseInt(a.ModifierIndex) - parseInt(b.ModifierIndex)
            );
            return (
              <React.Fragment key={item.ItemID}>
                <TableContainer
                  id="checkoutTable"
                  component={Paper}
                  style={{ backgroundColor: "#EAEAEA" }}
                >
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell width="150px" className="checkcell01">
                          <img
                            // alt={item.FileName}
                            height="100%"
                            width="100%"
                            // title={item.FileName}
                            loading="lazy"
                            src={
                              item.FileName && item.FileName.length > 0
                                ? siteImgSrc + item.FileName
                                : null
                            }
                            style={{ borderRadius: "10px" }}
                          />
                        </TableCell>

                        <TableCell className="checkcell02">
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{
                              fontWeight: 700,
                              fontSize:
                                enableKiosk === "0" ? "1.25rem" : "1.5rem",
                            }}
                            gutterBottom
                          >
                            {item.ItemName}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" className="checkcell03">
                          <Grid container justify="flex-end">
                            <Box
                              borderRadius="4px"
                              display="flex"
                              alignItems="center"
                              padding={
                                enableKiosk === "0" ? ".125rem" : ".75rem"
                              }
                              color="white"
                              style={{
                                backgroundColor: buttonColor,
                                color: buttonText,
                                borderColor: buttonColor,
                                border: "none",
                              }}
                            >
                              <Button
                                style={{
                                  minWidth: "40px",
                                  backgroundColor: buttonColor,
                                  color: buttonText,
                                  borderColor: buttonColor,
                                  border: "none",
                                }}
                              >
                                <RemoveIcon
                                  style={{
                                    backgroundColor: buttonColor,
                                    color: buttonText,
                                    borderColor: buttonColor,
                                    border: "none",
                                  }}
                                  fontSize={
                                    enableKiosk === "0" ? "normal" : "1.5rem"
                                  }
                                  onClick={() => {
                                    if (item.quantity > 1) {
                                      const newCartItems = cartItems.map(
                                        (cartItem) => {
                                          if (cartItem.ItemID === item.ItemID) {
                                            return {
                                              ...item,
                                              quantity: item.quantity - 1,
                                            };
                                          }

                                          return cartItem;
                                        }
                                      );

                                      dispatch({
                                        type: "SET_CART",
                                        payload: newCartItems,
                                      });
                                    } else {
                                      const filterCartItems = cartItems
                                        .map((i) => i)
                                        .filter((j) => j !== item);
                                      dispatch({
                                        type: "SET_CART",
                                        payload: filterCartItems,
                                      });
                                    }
                                  }}
                                />
                              </Button>
                              <Box
                                style={{
                                  backgroundColor: buttonColor,
                                  color: buttonText,
                                  borderColor: buttonColor,
                                  border: "none",
                                }}
                                fontSize={
                                  enableKiosk === "0" ? "normal" : "1.5rem"
                                }
                                margin="0"
                              >
                                {item.quantity}
                              </Box>
                              <Button
                                style={{
                                  minWidth: "40px",
                                  backgroundColor: buttonColor,
                                  color: buttonText,
                                  borderColor: buttonColor,
                                  border: "none",
                                }}
                              >
                                <AddIcon
                                  style={{
                                    backgroundColor: buttonColor,
                                    color: buttonText,
                                    borderColor: buttonColor,
                                    border: "none",
                                  }}
                                  fontSize={
                                    enableKiosk === "0" ? "normal" : "1.5rem"
                                  }
                                  onClick={() => {
                                    const newCartItems = cartItems.map((i) => {
                                      if (i === item) {
                                        return {
                                          ...i,
                                          quantity: i.quantity + 1,
                                        };
                                      }
                                      return i;
                                    });

                                    dispatch({
                                      type: "SET_CART",
                                      payload: newCartItems,
                                    });
                                  }}
                                />
                              </Button>
                            </Box>
                          </Grid>
                        </TableCell>

                        <TableCell align="right" className="checkcell04">
                          <Typography
                            component="h2"
                            variant="h5"
                            style={{
                              fontWeight: 700,
                              paddingRight: "4.5px",
                              fontSize:
                                enableKiosk === "0" ? "1.25rem" : "1.5rem",
                            }}
                            gutterBottom
                          >
                            $ {numberWithCommas(item.DefaultPrice / 100)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell className="checkcell01"></TableCell>

                        <TableCell className="checkcell02">
                          {item.modifiers ? (
                            <Typography variant="subtitle2"></Typography>
                          ) : null}

                          {item.modifiers
                            ? modifiers.map((mod) => (
                                <>
                                  <Typography
                                    style={{
                                      fontSize:
                                        enableKiosk === "0"
                                          ? "1.125rem"
                                          : "1.5rem",
                                    }}
                                  >
                                    {mod.ItemDescription}
                                  </Typography>
                                </>
                              ))
                            : null}
                        </TableCell>

                        <TableCell
                          align="center"
                          className="checkcell03"
                        ></TableCell>

                        <TableCell align="right" className="checkcell04">
                          {item.modifiers
                            ? modifiers.map((mod) => (
                                <>
                                  <Typography
                                    style={{
                                      paddingRight: "8.5px",
                                      fontSize:
                                        enableKiosk === "0"
                                          ? "1.25rem"
                                          : "1.5rem",
                                    }}
                                  >
                                    $ {numberWithCommas(mod.DefaultPrice / 100)}
                                  </Typography>
                                </>
                              ))
                            : null}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            );
          })}
        </Grid>
        <Box height="1rem" />

        <TableContainer id="checkoutTotal" component={Paper}>
          <Table>
            <TableBody>
              {/* -----------Sub Total-----------*/}
              <TableRow>
                <TableCell
                  className="totalcell01"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                    gutterBottom
                  >
                    Subtotal
                  </Typography>
                </TableCell>

                <TableCell
                  className="totalcell02"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                ></TableCell>

                <TableCell
                  className="totalcell03"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                ></TableCell>

                <TableCell
                  align="right"
                  className="totalcell04"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{
                      fontWeight: 700,
                      marginRight: "1px",
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                    gutterBottom
                  >
                    $ {numberWithCommas(totalPriceItems)}
                  </Typography>
                </TableCell>
              </TableRow>
              {/* -----------Sub Total-----------*/}

              {/* -----------Promo Code-----------*/}
              <TableRow>
                <TableCell
                  className="totalcell01"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                >
                  {promoCode && discount > 0 ? (
                    <>
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{
                          fontWeight: 700,
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                        }}
                        gutterBottom
                      >
                        Promo Code{" "}
                        {discountPercentage != 0
                          ? "(-" + discountPercentage + "%)"
                          : ""}
                        {promoCode}
                        <RemoveCircleIcon
                          style={{ color: "black" }}
                          fontSize="large"
                          onClick={() => {
                            dispatch(setPromoCode(""));
                            setTempPromoCode("");
                          }}
                        />
                      </Typography>
                    </>
                  ) : null}
                </TableCell>
                <TableCell
                  className="totalcell02"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  className="totalcell03"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  className="totalcell04"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                >
                  {promoCode && discount > 0 ? (
                    <>
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{ fontWeight: 700, marginRight: "1px" }}
                        gutterBottom
                      >
                        -$ {numberWithCommas(discount)}
                      </Typography>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
              {/* -----------Promo Code-----------*/}

              {/* Taxes and Fees */}

              <TableRow
                style={{ backgroundColor: "#eaeaea", cursor: "pointer" }}
                onClick={() => setShowTaxesAndFees(!showTaxesAndFees)}
              >
                <TableCell
                  className="totalcell01"
                  style={{ padding: "10px 16px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "left",
                      width: "100%",
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                      gutterBottom
                    >
                      Taxes and Fees
                    </Typography>
                    <ExpandMoreIcon
                      style={{
                        transform: showTaxesAndFees
                          ? "rotate(180deg)"
                          : "rotate(0)",
                        width: enableKiosk === "1" ? "1.5em" : null,
                        height: enableKiosk === "1" ? "1.5em" : null,
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell
                  className="totalcell02"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                ></TableCell>
                <TableCell
                  align="center"
                  className="totalcell03"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  className="totalcell04"
                  style={{
                    fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                    padding: "2.5px 16px",
                  }}
                >
                  <Typography
                    component="h2"
                    variant="h5"
                    style={{
                      fontWeight: 700,
                      marginRight: "1px",
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                    }}
                    gutterBottom
                  >
                    ${" "}
                    {numberWithCommas(
                      +parseFloat(deliveryCharge) +
                        parseFloat(transactionFee) +
                        parseFloat(serviceCharge) +
                        parseFloat((totalPriceItems - discount) * tax)
                    )}
                  </Typography>
                </TableCell>
              </TableRow>

              {showTaxesAndFees && (
                <>
                  {/* -----------Tax-----------*/}
                  <TableRow id="taxesFees">
                    <TableCell
                      className="totalcell01"
                      style={{
                        fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                        padding: "2.5px 16px",
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{
                          fontWeight: 400,
                          color: "#565656",
                          fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                        }}
                        gutterBottom
                      >
                        Sales Tax ({tax * 100}%)
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="totalcell02"
                      style={{
                        fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                        padding: "2.5px 16px",
                      }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className="totalcell03"
                      style={{
                        fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                        padding: "2.5px 16px",
                      }}
                    ></TableCell>
                    <TableCell
                      align="right"
                      className="totalcell04"
                      style={{
                        fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                        padding: "2.5px 16px",
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{
                          fontWeight: 400,
                          color: "#565656",
                          marginRight: "1px",
                          fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                        }}
                        gutterBottom
                      >
                        ${" "}
                        {numberWithCommas(
                          parseFloat((totalPriceItems - discount) * tax)
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* -----------Tax-----------*/}

                  {/* -----------Delivery Charge-----------*/}
                  {deliveryCharge > 0 ? (
                    <TableRow>
                      <TableCell
                        className="totalcell01"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{
                            fontWeight: 400,
                            color: "#565656",
                            fontSize:
                              enableKiosk === "0" ? "1.25rem" : "1.5rem",
                          }}
                          gutterBottom
                        >
                          Delivery Charge{" "}
                          {deliveryChargePromoMessage ? (
                            <i
                              style={{
                                fontWeight: 300,
                                fontSize: "1vmax",
                              }}
                            >
                              {"("}
                              {deliveryChargePromoMessage}
                              {")"}
                            </i>
                          ) : null}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="totalcell02"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        className="totalcell03"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        className="totalcell04"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{
                            fontWeight: 400,
                            marginRight: "1px",
                            fontSize:
                              enableKiosk === "0" ? "1.25rem" : "1.5rem",
                          }}
                          gutterBottom
                        >
                          $ {numberWithCommas(parseFloat(deliveryCharge))}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {/* -----------Delivery Charge-----------*/}

                  {/* -----------Transaction Fee-----------*/}
                  {transactionFee > 0 ? (
                    <TableRow>
                      <TableCell
                        className="totalcell01"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{
                            fontWeight: 400,
                            color: "#565656",
                            fontSize:
                              enableKiosk === "0" ? "1.25rem" : "1.5rem",
                          }}
                          gutterBottom
                        >
                          {transactionFeeName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="totalcell02"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        className="totalcell03"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        className="totalcell04"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{
                            fontWeight: 400,
                            marginRight: "1px",
                            fontSize:
                              enableKiosk === "0" ? "1.25rem" : "1.5rem",
                          }}
                          gutterBottom
                        >
                          $ {numberWithCommas(parseFloat(transactionFee))}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {/* -----------Transaction Fee-----------*/}

                  {/* -----------Service Charge-----------*/}
                  {serviceCharge > 0 ? (
                    <TableRow>
                      <TableCell
                        className="totalcell01"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{
                            fontWeight: 400,
                            color: "#565656",
                            fontSize:
                              enableKiosk === "0" ? "1.25rem" : "1.5rem",
                          }}
                          gutterBottom
                        >
                          Service Charge
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="totalcell02"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        className="totalcell03"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      ></TableCell>
                      <TableCell
                        align="right"
                        className="totalcell04"
                        style={{
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          padding: "2.5px 16px",
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h5"
                          style={{
                            fontWeight: 400,
                            marginRight: "1px",
                            fontSize:
                              enableKiosk === "0" ? "1.25rem" : "1.5rem",
                          }}
                          gutterBottom
                        >
                          $ {numberWithCommas(parseFloat(serviceCharge))}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {/* -----------Service Charge-----------*/}
                </>
              )}
              {/* End Taxes and Fees */}

              {/* -----------Tip-----------*/}
              {parseInt(enableTip) === 1 && (
                <TableRow>
                  <TableCell
                    className="totalcell01"
                    style={{ padding: "8px 16px" }}
                  >
                    <Box
                      borderRadius="3px"
                      padding="1px"
                      display="flex"
                      alignItems="left"
                      color="white"
                      style={{
                        fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{
                          fontWeight: 700,
                          color: "black",
                          paddingTop: "8px",
                          fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                        }}
                        gutterBottom
                      >
                        Tip
                      </Typography>
                      <Button
                        style={{
                          textTransform: "none",
                          marginLeft: 15,
                          fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          backgroundColor: buttonColor,
                          color: buttonText,
                          borderColor: buttonColor,
                          width: enableKiosk === "0" ? "inheret" : "120px",
                        }}
                        onClick={() => setOpenTips(true)}
                      >
                        Change
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell
                    className="totalcell02"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      padding: "2.5px 16px",
                    }}
                  ></TableCell>
                  <TableCell
                    align="center"
                    className="totalcell03"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      padding: "2.5px 16px",
                    }}
                  ></TableCell>
                  <TableCell
                    align="right"
                    className="totalcell04"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      padding: "2.5px 16px",
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h5"
                      style={{
                        fontWeight: 700,
                        marginRight: "-2px",
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      }}
                      gutterBottom
                    >
                      $ {numberWithCommas(tips)}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {/* -----------Tip-----------*/}
              <TableRow style={{ backgroundColor: "#C0C0C0" }}>
                <TableCell className="totalcell01">
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      fontSize: enableKiosk === "0" ? "1.5rem" : "1.70rem",
                    }}
                  >
                    Amount Due
                  </Typography>
                </TableCell>
                <TableCell className="totalcell02"></TableCell>
                <TableCell align="center" className="totalcell03"></TableCell>
                <TableCell align="right" className="totalcell04">
                  <Typography
                    component="h3"
                    variant="h4"
                    style={{
                      fontWeight: 700,
                      marginRight: "-3.5px",
                      fontSize: enableKiosk === "0" ? "1.5rem" : "1.70rem",
                    }}
                  >
                    ${" "}
                    {numberWithCommas(
                      parseFloat(tips) +
                        parseFloat(deliveryCharge) +
                        parseFloat(transactionFee) +
                        parseFloat(serviceCharge) +
                        parseFloat(totalPriceItems - discount) +
                        parseFloat((totalPriceItems - discount) * tax)
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/*******Start Message to Kitchen *******/}
        {parseInt(enableKiosk) === 1 ? (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              * Enter your name
            </Typography>
            <Grid item xs={12}>
              <div>
                <TextField
                  className="eKioskInput"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => handleOnChangeName(e.target.value)}
                  onFocus={() => setKeyboardVisibleName(true)}
                  variant="outlined"
                  name="name"
                  value={name}
                  placeholder=""
                  autoComplete="off"
                />

                {keyboardVisibleName && (
                  <Keyboard
                    onKeyPress={(button) => handleOnKeyPressName(button)}
                    onChange={(input) => handleOnChangeName(input)}
                    inputName="name"
                  />
                )}
              </div>
            </Grid>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <div>
                <TextField
                  className="eKioskInput"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => handleOnChange(e.target.value)}
                  onFocus={() => setKeyboardVisible(true)}
                  variant="outlined"
                  name="message"
                  value={messageToKitchen}
                  placeholder=""
                  autoComplete="off"
                />

                {keyboardVisible && (
                  <Keyboard
                    onKeyPress={(button) => handleOnKeyPress(button)}
                    onChange={(input) => handleOnChange(input)}
                    inputName="message"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Typography
              gutterBottom
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                marginTop: enableKiosk === "0" ? "1.125rem" : "2rem",
              }}
            >
              Message to Kitchen
            </Typography>
            <Grid item xs={12}>
              <Form provider={methods} onSubmit={handleSubmit}>
                <Input
                  className={enableKiosk === "1" ? "eKioskInput" : null}
                  onChange={setKitchenMsg}
                  variant="outlined"
                  name="message"
                  placeholder="Enter special instructions"
                  autoComplete="off"
                />
              </Form>
            </Grid>
          </Grid>
        )}
        {/*******End Message to Kitchen *******/}

        {enableKiosk === "0" ? (
          <Grid container alignItems="center">
            <Typography
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
              }}
              component="h4"
              variant="subtitle1"
            >
              Selected Service Option<span style={{ color: "red" }}> *</span>
            </Typography>
            {sessionStorage.getItem("table") === "none" ? (
              <Button
                style={{
                  textTransform: "none",
                  marginLeft: 5,
                  padding: "0px",
                  marginLeft: "1rem",
                  fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                  backgroundColor: buttonColor,
                  color: buttonText,
                  borderColor: buttonColor,
                }}
                onClick={() => setOpenServiceOptions(true)}
              >
                Change
              </Button>
            ) : null}
          </Grid>
        ) : null}
        {enableKiosk === "0" ? (
          <Typography>
            {`${
              orderProcessingType === 0
                ? "Pickup"
                : orderProcessingType === 1
                ? "Dine-In"
                : "Delivery"
            } - 
    ${
      orderProcessingDate
        ? moment(orderProcessingDate).format("MMMM DD, YYYY h:mm A")
        : "Please Select Date & Time"
    }`}
          </Typography>
        ) : null}

        {/* <Typography style={{ fontWeight: 700, fontSize: enableKiosk === "0" ? "inherit" : "1.5rem" }} variant="subtitle1">
          Pick-up is only available at the St. Charles location.
        </Typography> */}

        <br />
        {enableKiosk === "0" ? (
          <Grid container>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
              }}
              component="h5"
              variant="subtitle1"
            >
              Customer Details<span style={{ color: "red" }}> *</span>
            </Typography>
            <Box
              width="100%"
              borderRadius="10px"
              padding="1rem"
              onClick={() => {
                setOpenSelectAddress(true);
              }}
              border="1px solid #bebebe"
            >
              {(!selectedAddress && (
                <>
                  <Typography variant="caption" style={{ color: "gray" }}>
                    Add Details
                  </Typography>
                  <Typography style={{ lineHeight: 1.2 }}>
                    {user?.name}
                  </Typography>
                </>
              )) || (
                <>
                  <Typography style={{ color: "gray" }} variant="caption">
                    Tap to Change Details
                  </Typography>
                  <Typography style={{ lineHeight: 1.2 }}>
                    {selectedAddress.first_name
                      ? selectedAddress.first_name +
                        " " +
                        selectedAddress.last_name +
                        ", " +
                        selectedAddress.email
                      : null}
                  </Typography>

                  <Typography style={{ lineHeight: 1.2 }}>
                    {selectedAddress.first_name
                      ? selectedAddress.address +
                        ", " +
                        selectedAddress.city +
                        ", " +
                        selectedAddress.zipCode
                      : null}
                  </Typography>
                  <Typography style={{ lineHeight: 1.2 }}>
                    {selectedAddress.phone}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        ) : null}
        <PaymentSelector setOpenUserCards={setOpenUserCards} />
        <Box height="1rem" />
        {selectedPayment && (
          <>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
              }}
              gutterBottom
            >
              Selected Mode of Payment
            </Typography>
            <span>
              <Typography>{selectedPayment}</Typography>
            </span>
          </>
        )}

        <Box height="1rem" />
        <Box
          bottom="0px"
          // position="fixed"
          left="0px"
          width="100%"
        >
          <Button
            style={{
              display: "none",
              padding: "11px 0rem",
              borderRadius: "10px",
              backgroundColor: cartItems.length ? "#ff0000" : "white",
              boxShadow: "none",
              fontSize: "16px",
              fontWeight: "bold",
              color: cartItems.length ? "#fff" : "#000",
              letterSpacing: "1px",
            }}
            fullWidth
            variant="contained"
            disabled={!cartItems.length}
            onClick={() => {
              if (
                ["Gift Card", "Rewards"].includes(selectedPayment) &&
                selectedPayment != ""
              ) {
                rewardsService.handleRewardsPayment(orderSession);
                alert("Order payment success!");
              }
            }}
          >
            Place order
          </Button>
        </Box>
      </Container>
      {enableKiosk === "1" ? (
        <>
          <AppBar
            position="fixed"
            sx={{
              background: "transparent",
              top: "auto",
              bottom: 0,
              boxShadow: "none",
            }}
          >
            <Toolbar>
              <Box sx={containerStyle}>
                <Button
                  variant="contained"
                  style={{
                    ...buttonWithGapStyle,
                    backgroundColor: "#00296F",
                    fontSize: "1.5rem",
                    width: "500px",
                  }}
                  onClick={newOrder}
                >
                  NEW ORDER
                  <AddShoppingCart
                    fontSize="large"
                    style={{
                      transform: "scale(1)",
                      width: "2em",
                      height: "2em",
                      padding: "9px",
                      marginLeft: "1.25rem",
                      backgroundColor: colorBackground,
                      color: "black",
                      borderRadius: "1em",
                    }}
                  />
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  style={{
                    ...buttonStyle,
                    backgroundColor: "#3D5309",
                    fontSize: "1.5rem",
                    width: "500px",
                  }}
                  onClick={processCardSwipePayment}
                >
                  PAY ORDER
                  <PriceCheckIcon
                    fontSize="large"
                    style={{
                      transform: "scale(1)",
                      width: "2em",
                      height: "2em",
                      padding: "9px",
                      marginLeft: "1.25rem",
                      backgroundColor: colorBackground,
                      color: "black",
                      borderRadius: "1em",
                    }}
                  />
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </>
      ) : null}
      <TipsDrawer open={openTips} setOpen={setOpenTips} />
      <SelectCreditCardDrawer
        open={openUserCards}
        setOpen={setOpenUserCards}
        tax={tax}
        deliveryCharge={deliveryCharge}
        transactionFee={transactionFee}
        serviceCharge={serviceCharge}
      />
      <ServiceOptionsDrawer
        deliveryCharge={deliveryCharge}
        updateDeliveryCharge={updateDeliveryCharge.bind(this)}
        open={openServiceOptions}
        setOpen={setOpenServiceOptions}
        startHour={startHour}
        startTime={startTime}
        endHour={endHour}
        endTime={endTime}
        endMinutes={endMinutes}
        interval={interval}
        openDays={openDays}
      />
      <SelectAddressDrawer
        open={openSelectAddress}
        setOpen={setOpenSelectAddress}
      />

      {/* <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Checkout Reminder!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography>
            <p>Pick-up is only available at the St. Charles location.</p>
            <p>
              For pickup orders at our new South Elgin location, please call the
              store at 707–754–3377.
            </p>
            <p>Thank You.</p>
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}
    </React.Fragment>
  );
};

export const ServiceOptionsDrawer = ({
  open,
  setOpen,
  deliveryCharge,
  updateDeliveryCharge,
  startHour,
  startTime,
  endHour,
  endTime,
  endMinutes,
  interval,
  openDays,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(false);
  const [timeConstant, setTimeConstant] = useState([]);
  //const [time, setTime] = useState(moment().add("20", "mins"));
  const { orderProcessingDate, orderProcessingType } = useSelector(
    (state) => state.public
  );
  const [cookie, setCookie, removeCookie] = useCookies([""]);

  const [address, setAddress] = useState();
  const [coords, setCoords] = useState();

  const updateTime = (e) => {
    //console.log(e, "E");
    // dispatch(setOrderProcessingDate(moment()))
    if (Date.parse(orderProcessingDate) > 0) {
      const timeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + e.value
      );
      //console.log(timeAndDate, "TIME AND DATE 1234");

      dispatch(setOrderProcessingDate(timeAndDate));
    } else {
      //alert("Invalid Date");
      const today = new Date();
      const defautTimeAndDate = moment(
        moment(orderProcessingDate).format("MMMM DD, YYYY") + " " + today
      );
      dispatch(setOrderProcessingDate(defautTimeAndDate));
    }
  };

  const setServiceOptionToPickup = () => {
    dispatch(setSelectedServiceOption(0));
    const address = getCookie("sic_address");
    if (address) {
      dispatch(setSelectedAddress(JSON.parse(address)));
    }
  };

  useEffect(() => {
    if (enableDineIN == 1) {
      dispatch(setSelectedServiceOption(1));
    }
  }, []);

  useEffect(() => {
    if (timeConstant.length > 0) {
      timeConstant.map((time, i) => {
        if (i === 0) {
          updateTime({
            label: time.label,
            value: time.value,
          });
        }
      });
    }
  }, [timeConstant]);

  useEffect(() => {
    var d = new Date();
    var selectedDate =
      Date.parse(orderProcessingDate) > 0 ? new Date(orderProcessingDate) : d;

    var currentHour = d.getHours();
    var min = d.getMinutes();

    var isSameDay =
      selectedDate.getDate() === d.getDate() &&
      selectedDate.getMonth() === d.getMonth() &&
      selectedDate.getFullYear() === d.getFullYear();

    let endHourValue = endHour > startHour ? endHour : endHour + 24;
    if (currentHour >= endHourValue && isSameDay && currentHour && endHour) {
      dispatch(
        setOrderProcessingDate(
          moment(selectedDate, "MM-DD-YYYY").add(1, "days")
        )
      );
    }

    // If the current hour is within the range, start from the current hour
    if (currentHour > startHour && isSameDay) {
      startHour = currentHour;
      startHour = orderProcessingType == 2 ? startHour + 1 : startHour;
    }

    var tempTimeConstant = [];

    if (selectedDate && startHour && endHour && interval) {
      setTimeConstant([]);

      let currentTime = startHour * 60; // Convert start hour to minutes
      let endTime = (endHour > startHour ? endHour : endHour + 24) * 60; // Convert end hour to minutes

      while (currentTime < endTime) {
        let hour = Math.floor(currentTime / 60) % 24;
        let minute = currentTime % 60;
        let displayHour = hour; // We introduce a separate variable for display purposes
        let period = hour < 12 || hour === 24 ? "AM" : "PM";

        if (hour === 0) {
          // If hour is 00, adjust for AM display
          displayHour = 12;
        } else if (hour > 12) {
          displayHour -= 12;
        }

        // If the current time is less than the current real time, then skip it
        if (isSameDay && hour === currentHour && minute <= min) {
          currentTime += interval;
          continue;
        }

        // Check if the gap to the next hour is less than the interval when it's the same day and same current hour
        if (currentHour === hour && isSameDay) {
          if (minute < min || minute < (min + interval) % 60) {
            currentTime += interval;
            continue;
          }
        }

        //For 00 minutes
        if (
          isSameDay &&
          hour - currentHour === 1 &&
          minute === 0 &&
          60 - min < interval
        ) {
          currentTime += interval;
          continue;
        }

        let timeSlot = `${displayHour < 10 ? "0" + displayHour : displayHour}:${
          minute < 10 ? "0" + minute : minute
        } ${period}`;

        let timeOption = new Date(d.getTime());
        timeOption.setHours(hour);
        timeOption.setMinutes(minute);

        tempTimeConstant.push({
          label: timeSlot,
          value: timeSlot,
        });

        currentTime += interval;
      }

      setTimeConstant(tempTimeConstant);
    }

    //logic for pickup always next date
    if (isSameDay) {
      console.log(orderProcessingType, "orderProcessingType");
      console.log(enableSameDayPickup, "enableSameDayPickup");
      console.log(enalbeSameDayDelivery, "enalbeSameDayDelivery");
      if (
        (orderProcessingType == 0 && enableSameDayPickup == 0) ||
        (orderProcessingType == 2 && enalbeSameDayDelivery == 0)
      )
        dispatch(
          setOrderProcessingDate(moment(d, "MM-DD-YYYY").add(1, "days"))
        );
    }
  }, [date, startHour, endHour, orderProcessingType, interval]);

  useEffect(() => {
    setDate(moment());
  }, []);

  // let timeConstant = [
  //   { code: "", name: "Select Pickup Time" },
  //   { code: "11:00 am", name: "11:00 am" },
  //   { code: "11:15 am", name: "11:15 am" },
  //   { code: "11:30 am", name: "11:30 am" },
  //   { code: "11:30 am", name: "11:45 am" },
  //   { code: "12:00 pm", name: "12:00 pm" },
  //   { code: "12:15 pm", name: "12:15 pm" },
  //   { code: "12:30 pm", name: "12:30 pm" },
  //   { code: "01:00 pm", name: "01:00 pm" },
  //   { code: "01:15 pm", name: "01:15 pm" },
  //   { code: "01:30 pm", name: "01:30 pm" },
  //   { code: "02:00 pm", name: "02:00 pm" },
  //   { code: "02:15 pm", name: "02:15 pm" },
  //   { code: "02:30 pm", name: "02:30 pm" },
  //   { code: "03:00 pm", name: "03:00 pm" },
  //   { code: "03:15 pm", name: "03:15 pm" },
  //   { code: "03:30 pm", name: "03:30 pm" },
  //   { code: "04:00 pm", name: "04:00 pm" },
  //   { code: "04:15 pm", name: "04:15 pm" },
  //   { code: "04:30 pm", name: "04:30 pm" },
  //   { code: "05:00 pm", name: "05:00 pm" },
  //   { code: "05:30 pm", name: "05:30 pm" },
  //   { code: "06:00 pm", name: "06:00 pm" },
  //   { code: "06:30 pm", name: "06:30 pm" },
  //   { code: "07:00 pm", name: "07:00 pm" },
  //   { code: "07:30 pm", name: "07:30 pm" },
  //   { code: "08:00 pm", name: "08:00 pm" },
  //   { code: "08:30 pm", name: "08:30 pm" },
  //   { code: "09:00 pm", name: "09:00 pm" },
  // ];

  // console.log(orderProcessingDate, "first load");
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      <Box
        width="100%"
        padding="1rem"
        height="80vh"
        position="relative"
        overflow="auto"
      >
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 700,
            fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
          }}
          gutterBottom
        >
          Select Service Option
        </Typography>
        <Typography style={{ color: buttonText }} gutterBottom>
          {`${
            orderProcessingType == 0
              ? "Pickup"
              : orderProcessingType == 1
              ? "Dine-In"
              : "Delivery"
          } - ${moment(orderProcessingDate).format("MMMM DD, YYYY hh:mm a")}`}
        </Typography>
        <Box height="1rem" />
        <Grid container spacing={2}>
          {enablePickup == 1 ? (
            <Grid item xs={6}>
              <Box
                height="75px"
                display="flex"
                alignItems="center"
                borderRadius="10px"
                justifyContent="center"
                border={orderProcessingType != 0 && "1px solid #bebebe"}
                color={orderProcessingType == 0 && buttonText}
                bgcolor={orderProcessingType == 0 && buttonColor}
                onClick={() => dispatch(setSelectedServiceOption(0))}
              >
                <Typography variant="h6">Pickup</Typography>
              </Box>
            </Grid>
          ) : null}
          {enableDineIN == 1 ? (
            <Grid item xs={6}>
              <Box
                height="75px"
                display="flex"
                borderRadius="10px"
                alignItems="center"
                justifyContent="center"
                border={orderProcessingType != 1 && "1px solid #bebebe"}
                color={orderProcessingType == 1 && buttonText}
                bgcolor={orderProcessingType == 1 && buttonColor}
                onClick={() => dispatch(setSelectedServiceOption(1))}
              >
                <Typography variant="h6">Dine-In</Typography>
              </Box>
            </Grid>
          ) : null}

          {enalbeDelivery == 1 ? (
            <Grid item xs={6}>
              <Box
                height="75px"
                display="flex"
                borderRadius="10px"
                alignItems="center"
                justifyContent="center"
                border={orderProcessingType != 2 && "1px solid #bebebe"}
                color={orderProcessingType == 2 && buttonText}
                bgcolor={orderProcessingType == 2 && buttonColor}
                onClick={() => dispatch(setSelectedServiceOption(2))}
              >
                <Typography variant="h6">Delivery</Typography>
              </Box>
            </Grid>
          ) : null}

          {/* <div
            className="map-content"
            style={{ height: "290px", width: "100%" }}
          ></div> */}
        </Grid>
        <Box height="1rem" />

        <Box height="1rem" />
        <Grid>
          <Box
          // width="100%"
          >
            <Typography
              className="content"
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                color: buttonText,
              }}
            >
              Select date of{" "}
              {orderProcessingType == 0
                ? "Pickup"
                : orderProcessingType == 1
                ? "Dine-In"
                : "Delivery"}
            </Typography>
            <SingleDatePicker
              width="100%"
              className="content"
              focused={focused}
              numberOfMonths={1}
              date={orderProcessingDate}
              id="date_input"
              onDateChange={(date) => {
                if (date === null) {
                  dispatch(setOrderProcessingDate(moment("MM-DD-YYYY")));
                  setFocused(false);
                  setDate(moment("MM-DD-YYYY"));
                } else {
                  dispatch(setOrderProcessingDate(date));
                  setFocused(false);
                  setDate(date);
                }
              }}
              placeholder="Select date"
              orientation="horizontal"
              onFocusChange={(data) => {
                setFocused(data.focused);
              }}
              isDayBlocked={(day) => {
                const d = new Date(day);
                if (d.getDate() === 4 && d.getMonth() === 6) {
                  return true;
                }
                if (!openDays.includes(d.getDay())) {
                  return true;
                }
                //logic for pickup alway next date
                const currentDate = new Date();
                const cdate = currentDate.getDate();
                const cyear = currentDate.getFullYear();
                const cmonth = currentDate.getMonth();
                if (
                  d.getDate() == cdate &&
                  d.getMonth() == cmonth &&
                  d.getFullYear() == cyear
                ) {
                  if (
                    (orderProcessingType == 0 && enableSameDayPickup == 0) ||
                    (orderProcessingType == 2 && enalbeSameDayDelivery == 0)
                  )
                    return true;
                }
              }}
            />
          </Box>
        </Grid>
        <Box height="1rem" />
        <Grid
          container
          hidden={sessionStorage.getItem("table") == "none" ? false : true}
        >
          <Box width="100%">
            <Typography
              className="content"
              style={{
                fontWeight: 700,
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                color: buttonText,
              }}
            >
              Select time of{" "}
              {orderProcessingType == 0
                ? "Pickup"
                : orderProcessingType == 1
                ? "Dine-In"
                : "Delivery"}
            </Typography>
            <Select
              isSearchable={false}
              key={JSON.stringify(timeConstant)}
              options={timeConstant}
              defaultValue={timeConstant[0]}
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: "9",
                  fontSize: "1.25rem",
                }),

                singleValue: (provided, state) => ({
                  ...provided,
                  zIndex: "9",
                  fontSize: "1.25rem",
                }),
              }}
              onChange={updateTime}
            />
            {orderProcessingType == 2 ? (
              <DeliveryMap
                updateDeliveryCharge={updateDeliveryCharge.bind(this)}
                setOpen={setOpen.bind(this)}
                setServiceOptionToPickup={setServiceOptionToPickup}
              />
            ) : null}
          </Box>
        </Grid>
        <Box
          position="absolute"
          bottom="auto"
          left="0px"
          padding="1rem 0 0 0"
          width="100%"
        >
          <Button
            fullWidth
            elevation={0}
            style={{
              textTransform: "none",
              padding: "0rem 1rem",
              borderRadius: "10px",
              border: "1px solid #bebebe",
              backgroundColor: buttonColor,
              color: buttonText,
              borderColor: buttonColor,
              fontSize: "1.25rem",
              width: "100%",
            }}
            variant="contained"
            onClick={() => {
              setOpen(false);
              if (orderProcessingType == 2 && deliveryCharge == 0) {
                dispatch(setSelectedServiceOption(0));
              }
            }}
          >
            DONE
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export const SelectAddressDrawer = ({ open, setOpen }) => {
  const history = useHistory();
  const [addresses, setAddresses] = useState([]);
  const dispatch = useDispatch();
  const { user, selectedAddress, orderProcessingType } = useSelector(
    (state) => state.public
  );

  const initialValues = {
    first_name: selectedAddress ? selectedAddress.first_name : "",
    last_name: selectedAddress ? selectedAddress.last_name : "",
    email: selectedAddress ? selectedAddress.email : "",
    address: selectedAddress ? selectedAddress.address : "",
    city: selectedAddress ? selectedAddress.city : "",
    zipCode: selectedAddress ? selectedAddress.zipCode : "",
    phone: selectedAddress ? selectedAddress.phone : "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required"),
    address:
      orderProcessingType !== 2
        ? Yup.string().required("Address is required")
        : Yup.string(),
    city:
      orderProcessingType !== 2
        ? Yup.string().required("City is required")
        : Yup.string(),
    zipCode:
      orderProcessingType !== 2
        ? Yup.string().required("ZipCode is required")
        : Yup.string(),
    phone: Yup.string().required("Phone number is required"),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });
  const { errors, reset } = methods;

  const handleSubmit = async (d) => {
    console.log(d);

    selectedAddress.first_name = d.first_name;
    selectedAddress.last_name = d.last_name;
    selectedAddress.email = d.email;
    selectedAddress.address =
      orderProcessingType !== 2 ? d.address : selectedAddress.address;
    selectedAddress.city =
      orderProcessingType !== 2 ? d.city : selectedAddress.city;
    selectedAddress.zipCode =
      orderProcessingType !== 2 ? d.zipCode : selectedAddress.zipCode;
    selectedAddress.phone = d.phone;
    dispatch(setSelectedAddress(selectedAddress));
    reset(initialValues);
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      anchor="bottom"
      className="CheckoutDrawer"
    >
      <React.Fragment>
        <IconButton
          onClick={() => setOpen(false)}
          style={{ borderRadius: "1px", backgroundColor: "gray" }}
        >
          <ArrowBackIcon style={{ fontSize: "2rem", fill: "whitesmoke" }} />
        </IconButton>
        <Container style={{ position: "relative", minHeight: "90vh" }}>
          {addresses.map((add) => {
            return (
              <Box
                padding="1rem"
                borderRadius="10px"
                width="100%"
                margin="1rem 0rem"
                border="1px solid #bebebe"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Typography variant="subtitle1" color="primary">
                  {add.address + ", " + add.city + ", " + add.zipCode}
                </Typography>
                <Typography variant="caption">{add.phone}</Typography>
              </Box>
            );
          })}
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 700,
              fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
            }}
          >
            Customer Details
          </Typography>
          {/* {errors.phone ? toast.error(errors.phone.message) : null} */}
          <Form provider={methods} onSubmit={handleSubmit}>
            <Box height="1rem" />
            <Typography>First Name</Typography>
            <Input
              variant="outlined"
              name="first_name"
              placeholder="First Name"
              defaultValue={selectedAddress ? selectedAddress.first_name : ""}
            />
            <Typography>Last Name</Typography>
            <Input
              variant="outlined"
              name="last_name"
              placeholder="Last Name"
              defaultValue={selectedAddress ? selectedAddress.last_name : ""}
            />
            <Typography>Email</Typography>
            <Input
              variant="outlined"
              name="email"
              type="email"
              placeholder="email@email.com"
              defaultValue={selectedAddress ? selectedAddress.email : ""}
            />
            <Typography>Address</Typography>
            {orderProcessingType == 2 ? selectedAddress.address : ""}
            <Input
              hidden={orderProcessingType == 2 ? true : false}
              name="address"
              variant="outlined"
              placeholder="Address"
              defaultValue={selectedAddress ? selectedAddress.address : ""}
            />
            <Typography>City</Typography>
            {orderProcessingType == 2 ? selectedAddress.city : ""}
            <Input
              hidden={orderProcessingType == 2 ? true : false}
              name="city"
              variant="outlined"
              placeholder="City"
              defaultValue={selectedAddress ? selectedAddress.city : ""}
            />
            <Typography>Zip Code</Typography>
            {orderProcessingType == 2 ? selectedAddress.zipCode : ""}
            <Input
              onKeyDown={(e) => {
                if (parseInt(e.target.value) > 99999 && e.keyCode !== 8) {
                  e.preventDefault();
                }
                if (
                  (e.keyCode >= 48 && e.keyCode <= 57) ||
                  (e.keyCode >= 96 && e.keyCode <= 105) ||
                  e.keyCode == 8
                ) {
                } else {
                  e.preventDefault();
                }
              }}
              hidden={orderProcessingType == 2 ? true : false}
              name="zipCode"
              variant="outlined"
              placeholder="Zip Code"
              defaultValue={selectedAddress ? selectedAddress.zipCode : ""}
              autoComplete="off"
            />
            <Box height="6px" />
            <Typography gutterBottom>Mobile #</Typography>
            <Box height="6px" />
            <Controller
              as={
                <PhoneInput
                  defaultCountry="US"
                  inputClassName="customPhoneInput"
                  defaultValue={selectedAddress ? selectedAddress.phone : ""}
                />
              }
              name="phone"
            />
            <Box bottom="0px" left="0px" marginTop="2rem" width="100%">
              <Button
                type="submit"
                fullWidth
                fontSize="1.25rem"
                elevation={0}
                style={{
                  textTransform: "none",
                  padding: "9px 0rem",
                  borderRadius: "10px",
                  border: "1px solid #bebebe",
                  backgroundColor: buttonColor,
                  color: buttonText,
                  borderColor: buttonColor,
                }}
                variant="contained"
              >
                Save Details
              </Button>
            </Box>
          </Form>
        </Container>
      </React.Fragment>
    </Drawer>
  );
};

export const TipsDrawer = ({ open, setOpen }) => {
  const { totalPriceItems } = useSelector((state) => state.cart);
  const tipsConstant = [0.1, 0.15, 0.2, 0.25];
  const dispatch = useDispatch();
  const initialValues = {
    tip: 0,
  };

  const validationSchema = Yup.object({
    tip: Yup.mixed(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { setValue } = methods;

  const handleSubmit = (d) => {
    // console.log(d);
    dispatch(setTip(d.tip));
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"bottom"}>
      <Box padding="1rem" height="70vh" position="relative">
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 700,
            fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
          }}
        >
          Tips
        </Typography>
        <Toolbar />
        <Grid container spacing={2}>
          {tipsConstant.map((tip) => (
            <Grid key={tip} item xs={3}>
              <Button
                style={{
                  fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                  backgroundColor: buttonColor,
                  color: buttonText,
                  borderColor: buttonColor,
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  padding="0rem"
                  width="60px"
                  height="60px"
                  onClick={() =>
                    setValue("tip", (tip * totalPriceItems).toFixed(2))
                  }
                >
                  <Typography
                    className={
                      enableKiosk === "1" ? "eKioskInput" : "NormalInput"
                    }
                    style={{
                      fontSize: enableKiosk === "0" ? "1.125rem" : "1.25rem",
                    }}
                  >
                    {`${tip * 100} %`}
                  </Typography>
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
        <Toolbar />
        <Form provider={methods} onSubmit={handleSubmit}>
          <Input
            className={enableKiosk === "1" ? "eKioskAmount" : "NormalAmount"}
            label="Amount $"
            name="tip"
          />
          <Box
            position="absolute"
            bottom="auto"
            left="0px"
            padding="1rem"
            width="100%"
          >
            <Button
              style={{
                fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                backgroundColor: buttonColor,
                color: buttonText,
                borderColor: buttonColor,
              }}
              variant="contained"
              fullWidth
              type="submit"
            >
              Apply Tip
            </Button>
          </Box>
        </Form>
      </Box>
    </Drawer>
  );
};

const PaymentSelector = ({ setOpenUserCards }) => {
  const { selectedAddress, orderProcessingDate, orderProcessingType } =
    useSelector((state) => state.public);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflowX: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
  }));

  const classes = useStyles();

  const payments = [
    "Credit Cards",
    //"Gift Cards",
    //"Rewards",
  ];

  return (
    <>
      <br />
      {enableKiosk === "0" ? (
        <Typography
          style={{
            fontWeight: 700,
            fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
          }}
          gutterBottom
        >
          Types of Payment
        </Typography>
      ) : null}
      {enableKiosk === "0" ? (
        <div className={classes.root} style={{ "overflow-x": "visible" }}>
          <ImageList
            style={{ width: "auto !important", "overflow-x": "visible" }}
            className={classes.gridList}
            cols={2.2}
          >
            {payments.map((payment) => {
              return (
                <ImageListItem
                  key={payment}
                  style={{
                    height: "auto",
                    margin: "0rem 6px",
                    padding: "1rem 0rem",
                    width: "auto !important",
                    paddingLeft: "0",
                  }}
                >
                  <Box
                    style={{
                      cursor: "pointer",
                      backgroundColor: buttonColor,
                      color: buttonText,
                      borderColor: buttonColor,
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100px"
                    padding="1rem"
                    border="1px solid #bebebe"
                    borderRadius="5px"
                    onClick={() => {
                      if (selectedAddress !== null && orderProcessingDate) {
                        if (
                          orderProcessingType == 2 &&
                          !selectedAddress.address
                        ) {
                          Swal.fire({
                            title: "Store Message",
                            text: "Something is wrong with your delivery address, please check again!",
                            icon: "info",
                            confirmButtonText: "OK",
                            fontSize:
                              enableKiosk === "0" ? "1.125rem" : "1.5rem",
                          });
                        } else {
                          if (!selectedAddress.first_name) {
                            Swal.fire({
                              title: "Store Message",
                              text: "Please complete the customer details!",
                              icon: "info",
                              confirmButtonText: "OK",
                              fontSize:
                                enableKiosk === "0" ? "1.125rem" : "1.5rem",
                            });
                          } else {
                            setOpenUserCards(true);
                            dispatch({
                              type: "SET_SELECTED_MODE_PAYMENT",
                              payload: payment,
                            });
                          }
                        }
                      } else {
                        if (!orderProcessingDate) {
                          alert("Please provide Service Option details!");
                        } else {
                          alert("Please provide Customer details!");
                        }
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Typography>{payment}</Typography>
                      {payment === "Credit Cards" && (
                        <img
                          height="auto"
                          width="100%"
                          alt={payment}
                          title={payment}
                          loading="lazy"
                          src={siteMedSrc + "credit-card" + ".svg"}
                          style={{ maxWidth: "90px", margin: "-15px 0px" }}
                        />
                      )}
                      {payment === "Gift Cards" && (
                        <img
                          height="auto"
                          width="100%"
                          alt={payment}
                          title={payment}
                          loading="lazy"
                          src={siteMedSrc + "GIFTCARD" + ".png"}
                          style={{ maxWidth: "30px", margin: "-15px 0px" }}
                        />
                      )}
                      {payment === "Rewards" && (
                        <img
                          height="auto"
                          width="100%"
                          alt={payment}
                          title={payment}
                          loading="lazy"
                          src={siteMedSrc + "COIN" + ".png"}
                          style={{ maxWidth: "25px", margin: "-15px 0px" }}
                        />
                      )}
                    </Box>
                  </Box>
                </ImageListItem>
              );
            })}
          </ImageList>
          <PaymentNested />
        </div>
      ) : null}
    </>
  );
};

export const SelectCreditCardDrawer = ({
  open,
  setOpen,
  tax,
  deliveryCharge,
  transactionFee,
  serviceCharge,
}) => {
  const [userCards, setUserCards] = useState([]);
  const { user } = useSelector((state) => state.public);
  const { selectedPayment } = useSelector((state) => state.cart);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
      <Box padding="1rem" height="80vh" position="relative">
        <Box height="40px" />
        <Typography
          style={{
            fontWeight: 700,
            fontSize: enableKiosk === "0" ? "1.125rem" : "1.5rem",
          }}
        >
          {selectedPayment}
        </Typography>
        {userCards.map((card) => {
          return (
            <Box
              borderRadius="10px"
              border="1px solid #bebebe"
              padding="1rem 1rem"
              margin="1rem 0rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              overflow="auto"
              onClick={() => {
                dispatch(setModeOfPayment(card));
                setOpen(false);
              }}
            >
              <Box display="flex" alignItems="center">
                <input
                  type="radio"
                  checked={selectedPayment?.id === card.id}
                  style={{ marginRight: "1rem" }}
                />
                {card.CardType === "VISA" ? (
                  <img
                    height="100%"
                    width="100%"
                    title={card.CardType}
                    loading="lazy"
                    src={siteMedSrc + "visa" + ".png"}
                    alt="VISA"
                    style={{ width: "60px" }}
                  />
                ) : card.CardType === "AMEX" ? (
                  <img
                    height="100%"
                    width="100%"
                    title={card.CardType}
                    loading="lazy"
                    alt="AMEX"
                    src={siteMedSrc + "AMEX" + ".png"}
                    style={{ width: "60px" }}
                  />
                ) : card.CardType === "M/C" ? (
                  <img
                    height="100%"
                    width="100%"
                    title={card.CardType}
                    loading="lazy"
                    src={siteMedSrc + "mastercard" + ".png"}
                    alt="mastercard"
                    style={{ width: "60px" }}
                  />
                ) : (
                  <Typography>{card.CardType}</Typography>
                )}
              </Box>
              <Typography>{card.MaskedAccount}</Typography>
            </Box>
          );
        })}

        {selectedPayment == "Rewards" && <RewardsAsPayment setOpen={setOpen} />}

        {selectedPayment == "Gift Cards" && (
          <GiftCardAsPayment setOpen={setOpen} />
        )}

        {selectedPayment == "Credit Cards" && (
          <Button
            style={{
              cursor: "pointer",
              backgroundColor: buttonColor,
              color: buttonText,
              borderColor: buttonColor,
            }}
            fullWidth
            onClick={() => {
              history.push("/payment-wpay/", {
                tax: tax,
                deliveryCharge: deliveryCharge,
                transactionFee: transactionFee,
                serviceCharge: serviceCharge,
              });
            }}
          >
            use new card
          </Button>
        )}
      </Box>
    </Drawer>
  );
};

// Select credit cart
// redirect to form
// call iframe
// collect response
// save db
