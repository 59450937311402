import React, { useEffect, useState } from "react";
import "../../../App.css";
import Head from 'next/head';
import {
  Container,
  Row,
  Col,
  Button,
  h5,
  h1,
  h6,
  p,
  h2,
  div,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import config from "../../../config";

const siteId = process.env.REACT_APP_SITE_ID;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const textHighlight = process.env.REACT_APP_BACKGROUND_COLOR;

export const About = () => {

  const history = useHistory();
  const [highlightList, setHighlightList] = useState([]);
  const [aboutBusiness, setAboutBusiness] = useState('');
  const [littleAboutus, setlittleAboutus] = useState('');
  const [aboutContent, setaboutContent] = useState('');

  useEffect(() => {
    const itemsString = process.env.REACT_APP_BUSINESS_HIGHLIGHTS || '';

    const itemsArray = itemsString.split(',');

    setHighlightList(itemsArray);
  }, []);

  useEffect(() => {
    const aboutBusinessText = config.aboutbusiness;

    setAboutBusiness(aboutBusinessText);
  }, []);

  useEffect(() => {
    const littleAboutusText = config.littleaboutus;

    setlittleAboutus(littleAboutusText);
  }, []);

  useEffect(() => {
    const aboutContentText = config.aboutcontent;

    setaboutContent(aboutContentText);
  }, []);

  return (
    <div className="About">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${restaurantName}: ${aboutContent.title}`}</title>
          <link rel="canonical" href="/about" />
          <meta name="description" content={`${aboutContent.description}`} />
          <meta name="keywords" content={`${aboutContent.keywords}`} />
          <meta property="og:title" content={`${restaurantName}: ${aboutContent.title}`} />
          <meta property="og:description" content={`${aboutContent.description}`} />
        </Helmet>
      </HelmetProvider>
      <MenuNavigation />
      <div className="HeaderImg about-info"
        style={{
          backgroundImage: `url(${siteMedSrc}about_us_bg.png)`, color: buttonText
        }}
      >
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={8}>
              <p className="white" style={{ fontSize: "large" }}>
                {aboutContent.subtitle}
              </p>
              <h1 className="left" style={{ color: textHighlight }}>
                {aboutContent.title}
              </h1>
            </Col>
            <Col xs={8}>
              <p className="white title" style={{ fontSize: "large" }}>
                {aboutContent.description}
              </p>
              <Button
                className="btn-red margin-top-10 margin-bottom-10"
                style={{
                  backgroundColor: buttonColor,
                  color: buttonText,
                  borderColor: buttonColor,
                }}
                onClick={() => {
                  history.push("/menu");
                  window.scrollTo(0, 0);
                }}
              >
                Order Now{" "}
              </Button>
            </Col>
            <col className="whitespace"></col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <div className="col-lg-4">
            <div className="about-info-left" style={{ backgroundColor: colorBackground }}>
              <h2>Highlights from the Business</h2>
              <ul className="paddingSpecial">
                {highlightList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-info-right">
              <Typography variant="h4">
                Welcome to {restaurantName}
              </Typography>
              <p style={{ fontSize: "normal" }}>
                {aboutContent.message}
              </p>
              <Typography variant="h4" className="title">
                About the Business
              </Typography>
              <p style={{ fontSize: "normal" }} paragraph align="justify">
                {aboutBusiness.paragraph1}
              </p>
              <p style={{ fontSize: "normal" }} paragraph align="justify">
                {aboutBusiness.paragraph2}
              </p>
              <p style={{ fontSize: "normal" }} paragraph align="justify">
                {aboutBusiness.paragraph3}
              </p>
              {siteId == 142 && (
                <div>
                  <Typography variant="h4" className="title">
                    A little about us...
                  </Typography>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph1}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph2}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph3}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph4}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph5}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph6}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph7}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph8}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph9}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph10}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph11}
                  </p>
                </div>
              )}
              {siteId == 151 && (
                <div>
                  <Typography variant="h4" className="title">
                    Roma Catering - Aliquippa, Pennsylvania - Celebrating 30 Years
                  </Typography>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph1}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph2}
                  </p>
                  <p style={{ fontSize: "normal" }} paragraph align="justify">
                    {littleAboutus.paragraph3}
                  </p>
                </div>
              )}
              {siteId == 89 && (
                <div>
                  <h2 className="whitespace">4 Core Values</h2>
                  <div className="about-right-text left">
                    <Col xs={4} md={2} className="core-icon">
                      <img src={siteMedSrc + "loving-all-people-icon" + ".png"} style={{ width: 'auto' }} alt="Loving All People" />
                    </Col>
                    <Col xs={8} md={10} className="paddingRight15">
                      <h2>Loving All People</h2>
                      <h6 align="justify">
                        We define “love” as intentionally placing the needs of others above our own needs, wants, and desires. We recognize the significance of each person and strive to help others see what God sees in them. AJ's Brands believes everyone has an essential role in loving the people in our world; our customers, team members, vendors, and the charities we serve.
                      </h6>
                    </Col>
                  </div>
                  <div className="about-right-text left">
                    <Col xs={4} md={2} className="core-icon">
                      <img src={siteMedSrc + "impacting-the-world-icon" + ".png"} style={{ width: 'auto' }} alt="Impacting the World" />
                    </Col>
                    <Col xs={8} md={10} className="paddingRight15">
                      <h2>Impacting the World</h2>
                      <h6 align="justify">
                        We are called to serve as God commanded, “Do not forget to do good and to share with others, for with such sacrifices God is pleased” (Hebrews 13:16). AJ's Brands understands that we exist to serve our customers; our team members, and the community. Our support for Fox Valley Christian Action and Lazarus House changes lives here in the Fox Valley. Likewise, our support for Hope Coffee provides the resources to save and change lives worldwide.
                      </h6>
                    </Col>
                  </div>
                  <div className="about-right-text left">
                    <Col xs={4} md={2} className="core-icon">
                      <img src={siteMedSrc + "creating-superheroes-icon" + ".png"} style={{ width: 'auto' }} alt="Creating Superheroes" />
                    </Col>
                    <Col xs={8} md={10} className="paddingRight15">
                      <h2>Creating Superheroes</h2>
                      <h6 align="justify">
                        Behind every great hero is someone (or something) enabling them to be the leader the world needs. God tells us, “Be strong and courageous…for the Lord your God will be with you wherever you go” (Joshua 1:9). We want to be there for you. AJ's Brands empowers our team members with the capabilities and collaboration that turn them into superheroes to the customers they serve. Our impact is defined not just by the products we create and sell but by how we service our customers daily.
                      </h6>
                    </Col>
                  </div>
                  <div className="about-right-text left">
                    <Col xs={4} md={2} className="core-icon">
                      <img src={siteMedSrc + "exploding-the-box-icon" + ".png"} style={{ width: 'auto' }} alt="Exploding the Box" />
                    </Col>
                    <Col xs={8} md={10} className="paddingRight15">
                      <h2>Exploding the Box</h2>
                      <h6 align="justify">
                        AJ's Brands goes beyond “thinking outside the box.” When we are finished, the box will be unrecognizable. We create healthy, incredible drinks and food items which the market has rarely experienced, free of toxic sugar, gluten, and wheat. God continually surprises us as He is “able to do immeasurably more than all we ask or imagine, according to His power that is at work within us” (Ephesians 3:20).
                      </h6>
                    </Col>
                  </div>
                </div>
              )}
            </div>
            <div className="inner-about-gallery margin-top-30 ">
              <div className="col-lg-12">
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title={`${restaurantName} About Us Image1`}
                    src={siteMedSrc + "about-img-01" + ".jpg"}
                    alt={`${restaurantName} About Us Image1`}
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title={`${restaurantName} About Us Image2`}
                    src={siteMedSrc + "about-img-02" + ".jpg"}
                    alt={`${restaurantName} About Us Image2`}
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 float-left">
                  <img
                    width="100%"
                    height="100%"
                    title={`${restaurantName} About Us Image3`}
                    src={siteMedSrc + "about-img-03" + ".jpg"}
                    alt={`${restaurantName} About Us Image3`}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default withRouter(About);
