import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Container, Row, Col } from "react-bootstrap";
import { Link, scroller } from 'react-scroll';
import Tabs from '@mui/material/Tabs';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const categoriesFiltered = process.env.REACT_APP_CATEGORIES_FILTERED;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const textHighlight = process.env.REACT_APP_BACKGROUND_COLOR;

export const DiscoverMenu = () => {

  const [categories, setCategories] = useState([]);
  const updateTime = '';
  const history = useHistory();

  useEffect(() => {
    const url =
      parseInt(categoriesFiltered) === 1
        ? `https://ecommv2.servingintel.com/items/categories-filtered/${serverId}/${siteId}`
        : `https://ecommv2.servingintel.com/items/categories/${siteId}?server_id=${serverId}`;

    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => { });
  }, [updateTime]);

  return (
    <div className="MainContainerImg whitespace"
      style={{
        backgroundImage: `url(${siteMedSrc}tagline_bg4.png)`, color: buttonText
      }}
    >
      <Container>
        <Row>
          <col className="whitespace"></col>
          <Col xs={12}>
            <h2 className="center white">Explore Our Menu</h2>
          </Col>
          <h3
            style={{ fontSize: "1.25rem", color: textHighlight }}
            className="whitespace center"
          >
            Indulge in a culinary journey at {restaurantName} and explore our exquisite menu, crafted to tantalize your taste buds.
          </h3>
          <col className="whitespace"></col>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tabs variant="scrollable" className="DiscoverList" width="250px" >
              {categories.map((item) => (
                <li key={item.DepartmentID}
                  style={{
                    listStyleType: "none",
                  }}
                >
                  <Link>
                    <div style={{ position: "relative", width: "250px", height: "auto" }}>
                      <img
                        focusRipple
                        src={siteMedSrc + item.DepartmentID + ".png"}
                        style={{
                          width: "100%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          history.push("/menu");
                          window.scrollTo(0, 0);
                        }}
                        alt={item.DepartmentDescription}
                      />
                      <Button
                        style={{
                          backgroundColor: buttonColor,
                          color: buttonText,
                          borderColor: buttonColor,
                          cursor: "pointer",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        onClick={() => {
                          history.push("/menu");
                          window.scrollTo(0, 0);
                        }}
                      >
                        {item.DepartmentDescription}
                      </Button>
                    </div>
                  </Link>
                </li>
              ))}
            </Tabs>
          </Box>
        </Row>
      </Container>
    </div>
  );
};