import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../../config";
import "../../../App.css";

const siteId = process.env.REACT_APP_SITE_ID;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textHighlight = process.env.REACT_APP_BACKGROUND_COLOR;

export const OrderSlider = () => {

  const [orderContent, setorderContent] = useState('');

  useEffect(() => {
    const orderContentText = config.ordercontent;

    setorderContent(orderContentText);
  }, []);

  return (
    <div className="OrderSlider">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Order Now: Browse, view, and track your order.
          </title>
          <link rel="canonical" href="/menu" />
          <meta name="description" content={`${orderContent.description}`} />
          <meta name="keywords" content={`${orderContent.keywords}`} />
          <meta property="og:title" content={`${restaurantName}: Browse, view, and track your order`} />
          <meta property="og:description" content={`${orderContent.description}`} />
        </Helmet>
      </HelmetProvider>
      <div className="OrderHeader"
        style={{ backgroundImage: `url(${siteMedSrc}order-now-bg.png)`, color: textColor, height: "auto" }}
      >
        <Container>
          <Row>
            <col className="whitespace"></col>
            {siteId == 245 ? (
              <>
                <Row>
                  <Col sm={12} lg={8}>
                    <h1 className="left" style={{ color: textHighlight, visibility: 'hidden' }}>
                      {orderContent.title}
                    </h1>
                    <p className="white title" style={{ fontSize: "large", visibility: 'hidden' }}>
                      {orderContent.message}
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col sm={12} lg={8}>
                  <h1 className="left" style={{ color: textHighlight }}>{orderContent.title}</h1>
                  <p className="white title" style={{ fontSize: "large" }}>
                    {orderContent.message}
                  </p>
                </Col>
              </Row>
            )}
            <Col sm={0} lg={4}>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};