const config = {

    // Landing Page
    landingcontent: {
        title: "We are open! Serving Breakfast and Lunch same menu as Bread+Salt.",
        description: "While Bagalis is classically Italian in many aspects, we also incorporate our owner-chef’s international roots and passion for fresh, local ingredients to create an exceptional dining experience in the heart of Frisco Main Street.",
        keywords: "Bagalis, Casual dining, Atmosphere, Quality ingredients, Family-friendly, International influence, Family-owned restaurant, Variety of drinks, Tap beverages, Knowledgeable bar staff, Recommendations, Pasta, Comfort food, Table, Stories, Fine wines, Refreshing beverages, Knowledgeable staff, Perfect pairing, Flavors, Truffle fries, Happy Hour, Specials, Date night, Ambiance, Hospitality, Italian food, Pizzas, Rustic menu, Sophisticated menu, Chef-owned, Michal Ulehla, Joyce De La Torre, Czech Republic, Local ingredients, Summit County, Blue River Bistro, Hearthstone, The Cellar, Sonoma County, Wine country, Alinea, Michelin-ranked restaurant, Chicago, Raya, Rocco, Natalia, Mia, Outdoor lifestyle, Community, Customer service, Affordability, Quality, Food and beverage backgrounds, Multi-cultural backgrounds, Mexico, Eastern Europe, Cuisine, Variety, Locally owned, Success story, Commitment, Harmonious relationship, Symbiotic relationship, Seasonal menu, Decor, Fine dining, Happy hour specials, Two-for-one pizzas, Appetizers, Community table, Large groups, Fireside seating, Bar atmosphere, Fresh face, Fresh food, Something for everyone, Frisco, Main Street, Breakfast, Lunch, Local Colorado products, Rustic, Michelin-ranked properties, Healdsburg, Colorado, Clean slate, Affordable wines, Fun, Affordable, Bread, Desserts, Locally sourced, Hand-made foods, Commitment to customer service, Commitment to affordability, Commitment to quality, Happy hour from 4:30 to 6 p.m., Fireside seating.",
    },

    // About Us Page
    aboutcontent: {
        title: "A Culinary Journey in Frisco, Colorado",
        subtitle: "Chef-Owned Excellence with Locally Inspired Flavors and Community Charm",
        description: "Bagalis, a chef-owned restaurant in Frisco, Colorado, opened in 2011, presents an internationally inspired menu with locally sourced ingredients, offering a comfortable atmosphere, reasonable prices, and a commitment to customer service.",
        keywords: "Italian cuisine, international influence, Frisco, Colorado, culinary journey, traditions, flavors, craftsmanship, housemade bread, pasta, desserts, passion, dedication, gastronomic adventure, borders, freshly baked bread, handcrafted desserts, authentic, global twist, Frisco dining, love, taste, world, plate, Book, private event, Bagalis, Family reunions, Birthday gatherings, business luncheons, small group, brunch, happy hour, appropriately priced, mushroom flatbread, favorite place, Main Street, pizzas, half off, Begali, tomato basil soup, Burrata appetizer, service, prompt, attentive, busy, breakfast, Bread & Salt, menu, Frisco Omelette, Chicken Pesto sandwich, Bolognese, worth every penny, vegetarian options, bland, meat eating friend, bad night, local Italian restaurant, Thanksgiving dinner, ice skating, late night snack, waitress, displeased, appetizers, customer service, awful, food, decent, noteworthy, Thanksgiving Special, fixed price, gravy, curdled, spoiled milk, options, traveling, wouldn't go back",
        message: "We are open! Serving Breakfast and Lunch same menu as Bread+Salt. We are Taking Reservations!",
    },
    aboutbusiness: {
        paragraph1:
            'While Bagalis is classically Italian in many aspects, we also incorporate our owner-chef’s international roots and passion for fresh, local ingredients to create an exceptional dining experience in the heart of Frisco Main Street.',
        paragraph2:
            'Bagalis offers casual dining with a great atmosphere. Our dishes are made from simple, quality ingredients that let the food shine, and our staff works to improve every day, on everything from new menu items to perfect wine pairings. Look no further for your new favorite Italian restaurant in Frisco, Colorado!',
    },
    littleaboutus: {
        paragraph1:
            'Established in 2011, Bagalis has undergone a transformation and is now under the ownership of Chef Michal Ulehla and manager Joyce De La Torre. This chef-owned establishment boasts an internationally inspired menu, curated with locally sourced ingredients. Chef-owned restaurants are renowned for their dedication to meticulously prepared cuisine, showcasing the utmost quality in ingredients, and Bagalis on Main Street Frisco, led by Chef Michal Ulehla and manager Joyce De La Torre, exemplifies this commitment.',
        paragraph2:
            'As a young individual in the Czech Republic, Ulehla cultivated his own produce in a personal garden and sold his harvest at local markets. This experience equipped him with the expertise to carefully choose and skillfully prepare exceptional food.',
        paragraph3:
            'Since 2009, Ulehla and his wife, business partner Joyce De La Torre, have overseen and contributed their culinary expertise to various establishments in Summit County. De La Torre has worked at renowned establishments such as the Blue River Bistro, the Hearthstone, and The Cellar, where Ulehla served as the head chef.',
        paragraph4:
            "The duo also spent a period residing and working in Sonoma County, California, aiming to broaden their expertise in the restaurant industry while immersing themselves in the ambiance of wine country. Ulehla gained valuable experience at Chicago's Alinea, a distinguished three-star Michelin-ranked restaurant, in addition to contributing his skills to other Michelin-ranked establishments around Healdsburg, California.",
        paragraph5:
            'However, the allure of Colorado proved irresistible, prompting the couple to return and, ultimately, assume ownership of the recently inaugurated Bagalis in Frisco in 2011. Accompanied by their golden retrievers, Raya and Rocco, and their daughter Natalia (with a second daughter, Mia, joining the family since), De La Torre and Ulehla joyfully embraced the return to the cherished outdoor lifestyle and the warm community they had come to appreciate in Summit County.',
        paragraph6:
            'Transitioning into ownership with a fresh start was akin to a dream realized for the duo. Their aspiration was to cultivate a welcoming ambiance with affordable pricing, emphasizing locally sourced, handcrafted dishes whenever feasible. In essence, they embarked on a journey from the ground up with Bagalis Italian restaurant in Frisco, Colorado, not only in terms of food and menus but also in their dedication to exemplary customer service, affordability, and quality.',
        paragraph7:
            '“The menu has undergone significant transformations,” De La Torre remarked regarding their acquisition. “The majority of it is now crafted in-house, from the bread to desserts. Our approach to service has also evolved considerably... we aim for everyone to feel embraced and thoroughly enjoy their experience. Our selection of wines is budget-friendly, and our primary goal is for everyone to have a great time.”',
        paragraph8:
            'Both proprietors boast extensive backgrounds in food and beverage, coupled with diverse cultural influences ranging from Mexico to Eastern Europe, evident in both the cuisine and ambiance. This fusion, complemented by Ulehla’s firsthand experience living close to the origin of the ingredients, forms the foundation of a locally owned success story. “While we embrace classical Italian elements, our menu reflects an international infusion of fresh ingredients,” De La Torre explained. “We are dedicated to fostering a harmonious and symbiotic relationship between global cuisines and wines. Our menu evolves based on the availability of seasonal ingredients, ensuring there are always exciting new offerings with each season.”',
        paragraph9:
            'Despite the exquisite decor and the couple’s impressive background in food and beverage, Bagalis, in the words of Torre, does not fall into the category of fine dining. “We offer a casual dining experience with a fantastic atmosphere,” she explained. “Our culinary philosophy revolves around using simple ingredients that allow the natural flavors to shine. We strive to source the finest ingredients, preferably from Colorado whenever feasible, and our dedicated staff is committed to continuous improvement, gaining knowledge about our food, wines, and cocktails each day.”',
        paragraph10:
            'To acquaint patrons with the exceptional offerings at the restaurant, Bagalis extends a daily happy hour from 4:30 to 6 p.m. This exclusive time frame includes specials on wine, beer, and cocktails, as well as enticing two-for-one deals on pizzas and appetizers.',
        paragraph11:
            "Whether it's the communal table designed for families and large groups, the cozy fireside seating, or the welcoming ambiance of the bar that entices customers, Bagalis restaurant in Frisco, Colorado presents a vibrant atmosphere and, more importantly, a menu with freshly prepared dishes that caters to everyone's preferences.",
    },

    // Contact Page
    contactcontent: {
        title: "Check out our menu! We are Taking Reservations!",
        description: "Our restaurant is a celebration of exceptional flavors and craftsmanship, offering a delectable menu featuring housemade bread, pasta, and desserts. Savor the passion and dedication we put into every dish, as we take you on a gastronomic adventure that transcends borders. From the warm aroma of freshly baked bread to the delightful sweetness of our handcrafted desserts, each bite tells a story of authentic Italian inspiration with a global twist.",
        keywords: "Italian cuisine, international influence, Frisco, Colorado, culinary journey, traditions, flavors, craftsmanship, housemade bread, pasta, desserts, passion, dedication, gastronomic adventure, borders, freshly baked bread, handcrafted desserts, authentic, global twist, Frisco dining, love, taste, world, plate, Book, private event, Bagalis, Family reunions, Birthday gatherings, business luncheons, small group, brunch, happy hour, appropriately priced, mushroom flatbread, favorite place, Main Street, pizzas, half off, Begali, tomato basil soup, Burrata appetizer, service, prompt, attentive, busy, breakfast, Bread & Salt, menu, Frisco Omelette, Chicken Pesto sandwich, Bolognese, worth every penny, vegetarian options, bland, meat eating friend, bad night, local Italian restaurant, Thanksgiving dinner, ice skating, late night snack, waitress, displeased, appetizers, customer service, awful, food, decent, noteworthy, Thanksgiving Special, fixed price, gravy, curdled, spoiled milk, options, traveling, wouldn't go back",
    },

    // Photo Gallery Page
    gallerycontent: {
        description: "Indulge in a culinary journey that blends the rich traditions of Italian cuisine with a touch of international flair right here in the heart of Frisco, Colorado! Our restaurant is a celebration of exceptional flavors and craftsmanship, offering a delectable menu featuring housemade bread, pasta, and desserts. Savor the passion and dedication we put into every dish, as we take you on a gastronomic adventure that transcends borders. From the warm aroma of freshly baked bread to the delightful sweetness of our handcrafted desserts, each bite tells a story of authentic Italian inspiration with a global twist. Come join us in Frisco, where every meal is a symphony of love and taste, bringing the world to your plate!",
        keywords: "Italian cuisine, international influence, Frisco, Colorado, culinary journey, traditions, flavors, craftsmanship, housemade bread, pasta, desserts, passion, dedication, gastronomic adventure, borders, freshly baked bread, handcrafted desserts, authentic, global twist, Frisco dining, love, taste, world, plate, Book, private event, Bagalis, Family reunions, Birthday gatherings, business luncheons, small group, brunch, happy hour, appropriately priced, mushroom flatbread, favorite place, Main Street, pizzas, half off, Begali, tomato basil soup, Burrata appetizer, service, prompt, attentive, busy, breakfast, Bread & Salt, menu, Frisco Omelette, Chicken Pesto sandwich, Bolognese, worth every penny, vegetarian options, bland, meat eating friend, bad night, local Italian restaurant, Thanksgiving dinner, ice skating, late night snack, waitress, displeased, appetizers, customer service, awful, food, decent, noteworthy, Thanksgiving Special, fixed price, gravy, curdled, spoiled milk, options, traveling, wouldn't go back",
        message: "Indulge in a culinary journey that blends the rich traditions of Italian cuisine with a touch of international flair right here in the heart of Frisco, Colorado! Come join us in Frisco, where every meal is a symphony of love and taste, bringing the world to your plate!",
    },

    // Menu Page
    ordercontent: {
        title: "Flavors Beyond Borders: A Culinary Symphony of Authentic Italian Inspiration",
        description: "Our restaurant is a celebration of exceptional flavors and craftsmanship, offering a delectable menu featuring housemade bread, pasta, and desserts. Savor the passion and dedication we put into every dish, as we take you on a gastronomic adventure that transcends borders. From the warm aroma of freshly baked bread to the delightful sweetness of our handcrafted desserts, each bite tells a story of authentic Italian inspiration with a global twist.",
        keywords: "Italian cuisine, international influence, Frisco, Colorado, culinary journey, traditions, flavors, craftsmanship, housemade bread, pasta, desserts, passion, dedication, gastronomic adventure, borders, freshly baked bread, handcrafted desserts, authentic, global twist, Frisco dining, love, taste, world, plate, Book, private event, Bagalis, Family reunions, Birthday gatherings, business luncheons, small group, brunch, happy hour, appropriately priced, mushroom flatbread, favorite place, Main Street, pizzas, half off, Begali, tomato basil soup, Burrata appetizer, service, prompt, attentive, busy, breakfast, Bread & Salt, menu, Frisco Omelette, Chicken Pesto sandwich, Bolognese, worth every penny, vegetarian options, bland, meat eating friend, bad night, local Italian restaurant, Thanksgiving dinner, ice skating, late night snack, waitress, displeased, appetizers, customer service, awful, food, decent, noteworthy, Thanksgiving Special, fixed price, gravy, curdled, spoiled milk, options, traveling, wouldn't go back",
        message: "Savor the passion and dedication we put into every dish, as we take you on a gastronomic adventure that transcends borders. From the warm aroma of freshly baked bread to the delightful sweetness of our handcrafted desserts, each bite tells a story of authentic Italian inspiration with a global twist.",
    },
};

export default config;