import React, { useState, useEffect } from "react";

import { Grid, Container } from "@material-ui/core";
import { RestaurantCard } from "./components/RestaurantCard";
import { useHistory } from "react-router-dom";
import { MainNavigation } from "./navigation/MainNavigation";
import { useSelector } from "react-redux";

export const Home = () => {
  useEffect(() => {
    document.title = "Order Now";
  }, []);

  const [restaurants, setRestaurants] = useState([]);
  const history = useHistory();
  const { mainSearch } = useSelector((state) => state.public);

  return (
    <React.Fragment>
      <MainNavigation />
      <div style={{ height: "5vh" }} />
      <Container>
        <Grid container spacing={3}>
          {restaurants.map((restaurant) => {
            if (
              !mainSearch ||
              restaurant?.restaurantName
                .toLowerCase()
                .includes(mainSearch.toLowerCase())
            ) {
              return (
                <Grid
                  xs={12}
                  sm={6}
                  lg={4}
                  spacing={1}
                  key={restaurant.id}
                  style={{ marginBottom: "1rem" }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/menu/`);
                    }}
                  >
                    <RestaurantCard
                      name={restaurant.restaurantName}
                      image={restaurant.image}
                      description={restaurant.description}
                    />
                  </div>
                </Grid>
              );
            }
          })}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
