import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { IconButton, useMediaQuery } from "@mui/material";
import { ChevronLeft, Segment } from "@mui/icons-material";
import { useHistory, useRouteMatch } from "react-router-dom";

const NavDrawer = ({ navMenuList = [] }) => {
  const isMobile = useMediaQuery("(max-width:760px)");
  const history = useHistory();
  const match = useRouteMatch();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setOpenDrawer(open);
  };

  return (
    <div>
      {isMobile && (
        <React.Fragment key={"nav-drawer"}>
          <IconButton onClick={toggleDrawer(true)}>
            <Segment />
          </IconButton>

          <Drawer
            anchor={"left"}
            open={openDrawer}
            onClose={toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <Box textAlign={"right"}>
                  <IconButton
                    sx={{
                      alignSelf: "right",
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                </Box>
                <Divider />

                {navMenuList.length > 0 &&
                  navMenuList.map((nav, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton onClick={() => history.push(nav.path)}>
                        <ListItemText
                          sx={{
                            color: (theme) =>
                              nav.path === match.url
                                ? theme.palette.primary.main
                                : theme.palette.grey[500],
                          }}
                          primary={nav.label}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
};

export default NavDrawer;
