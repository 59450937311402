import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../../config";
import "../../../App.css";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const restaurantAddress = process.env.REACT_APP_RESTAURANT_ADDRESS;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const serviceOptions = process.env.REACT_APP_SERVICE_OPTIONS;
const enableEloyalty = process.env.REACT_APP_ENABLE_ELOYALTY;
const textHighlight = process.env.REACT_APP_BACKGROUND_COLOR;
const welcomeTitle = process.env.REACT_APP_WELCOME_TITLE;

export const ContactSlider = () => {

  const [contactContent, setcontactContent] = useState('');

  useEffect(() => {
    const contactContentText = config.contactcontent;

    setcontactContent(contactContentText);
  }, []);

  return (
    <div className="ContactSlider">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Contact Us: {restaurantAddress}.
          </title>
          <link rel="canonical" href="/menu" />
          <meta name="description" content={`${contactContent.description}`} />
          <meta name="keywords" content={`${contactContent.keywords}`} />
          <meta property="og:title" content={`${restaurantName}: ${restaurantAddress}`} />
          <meta property="og:description" content={`${contactContent.description}`} />
        </Helmet>
      </HelmetProvider>
      <div className="ContactHeader"
        style={{
          backgroundImage: `url(${siteMedSrc}contact_us_bg.png)`, color: buttonText
        }}
      >
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col lg={8}>
              <p className="white" style={{ fontSize: "large" }}>
                Service Options: {serviceOptions}
              </p>
              <h1 className="left blankspace" style={{ color: textHighlight }}>
                {contactContent.title}
              </h1>
              <p className="white blankspace" style={{ fontSize: "large" }}>
                Spread the word and help your neighbors get to know {restaurantName}, today!
              </p>
              <p className="white" style={{ fontSize: "large" }}>
                {restaurantAddress}
              </p>
              {enableEloyalty === "1" ? (
                <Button
                  className="btn-red whitespace margin-bottom-10"
                  href="/register"
                  style={{ backgroundColor: buttonColor, color: buttonText, borderColor: buttonColor }}
                >
                  Join our AJ's Club Program{" "}
                </Button>
              ) : null}
            </Col>
            {enableEloyalty === "1" ? (
              <Col lg={4}>
                <img
                  className="PhonerightImg"
                  title={`${restaurantName} Phone Right Img`}
                  src={siteMedSrc + "PhoneImg" + ".png"}
                  alt={`${restaurantName} Phone Right Img`}
                  loading="lazy"
                />
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
    </div>
  );
};