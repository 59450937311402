import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MenuNavigation } from '../components/MenuNavigation';
import { Footer } from '../components/Footer';
import BlankSpace from "../components/BlankSpace";
import PhotoGalleryList from "../components/PhotoGalleryList";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from "@material-ui/core/Typography";
import config from "../../../config";
import "bootstrap/dist/css/bootstrap.min.css";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const textHighlight = process.env.REACT_APP_BACKGROUND_COLOR;

export const PhotoGallery = () => {

  const [galleryContent, setGalleryContent] = useState('');

  useEffect(() => {
    const galleryContentText = config.gallerycontent;

    setGalleryContent(galleryContentText);
  }, []);

  return (
    <div className="PhotoGallery">
      <MenuNavigation />
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Photo Gallery: Explore our Photo Gallery</title>
          <link rel="canonical" href="/photo-gallery" />
          <meta name="description" content={`${galleryContent.description}`} />
          <meta name="keywords" content={`${galleryContent.keywords}`} />
          <meta property="og:title" content={`${restaurantName}: Explore our Photo Gallery`} />
          <meta property="og:description" content={`${galleryContent.description}`} />
        </Helmet>
      </HelmetProvider>
      <div className="HeaderImg"
        style={{
          backgroundImage: `url(${siteMedSrc}photo-gallery-bg.png)`, color: buttonText
        }}
      >
        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12}>
              <h1 className="center" style={{ color: textHighlight }}>Explore our Photo Gallery</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <BlankSpace />
      <Container>
        <Col xs={10} className="center">
          <Typography component="h2" variant="h6" className="title whitespace">{galleryContent.message}</Typography>
        </Col>
        <BlankSpace />
        <Row className="whitespace">
          <Col>
            <PhotoGalleryList />
          </Col>
        </Row>
      </Container>
      <BlankSpace />
      <Footer />
    </div>
  );
};